"use strict";
angular.module('App')
    .controller('CtrlLotSearch', function ($rootScope, $scope, $state, $stateParams, $log, toaster, SvXHR, SvQuery) {
    // // VARS
    $scope.User = $rootScope.User;
    $scope.GridLotSearch = _.cloneDeep({
        signed: new Date(),
        created_at: new Date(),
        loading: false
    });
    const LotSearchXHR = new SvXHR('lots/description');
    // // FUNCTIONS
    $scope.GridLotSearch = {
        loading: false,
        description: '',
        inStoreOnly: true,
        // Pagination
        useExternalPagination: true,
        paginationPageSizes: [25, 50, 75],
        paginationPageSize: 25,
        paginationPageNumber: 1,
        totalItems: 0,
        // Sorting
        sortColumns: [],
        columnDefs: [
            {
                name: 'Lot #',
                field: 'visual_id',
                width: 120,
                filter: {
                    placeholder: ' Search..'
                }
            },
            {
                name: 'Description',
                cellClass: 'text-left pdg-l',
                field: 'description',
                filter: {
                    placeholder: ' Search..'
                },
                cellTemplate: `
						<div class="ui-grid-cell-contents"
							ng-bind-html="row.entity[col.field]"
							uib-tooltip="{{row.entity[col.field]}}"
							tooltip-append-to-body="true" tooltip-placement="top"></div>
					`
            },
            {
                name: 'Reserve',
                field: 'reserve_bid',
                width: 90,
                cellFilter: 'dosh'
            },
            {
                name: 'Status',
                field: 'status',
                width: 100,
                cellTemplate: `
						<div class="ui-grid-cell-contents"
							ng-bind-html="row.entity[col.field] | filterLotStatus"
							ng-class="{
								'bg-success': row.entity[col.field] == 2,
								'bg-info': row.entity[col.field] == 3,
								'bg-danger': row.entity[col.field] == 4,
								'bg-warning': row.entity[col.field] == 8
							}"></div>
					`
            },
            {
                name: 'Collected',
                field: 'collected_at',
                width: 110,
                enableCellEdit: false,
                cellTemplate: `
						<div class="ui-grid-cell-contents"
							ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'"></div>
					`
            },
            {
                name: 'Created',
                field: 'created_at',
                width: 110,
                enableCellEdit: false,
                cellTemplate: `
						<div class="ui-grid-cell-contents"
							ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'"></div>
					`
            }
        ]
    };
    $scope.GridLotSearch.onRegisterApi = function (gridApi) {
        $scope.GridLotSearchApi = gridApi;
        // // EVENT LISTENERS
        // Pagination
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
            $scope.GridLotSearch.paginationPageNumber = newPage;
            $scope.GridLotSearch.paginationPageSize = pageSize;
            $scope.LotSearchDescription();
        });
        // Sorting
        gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
            $scope.GridLotSearch.sortColumns = sortColumns;
            $scope.LotSearchDescription(true);
        });
    }; // END onRegisterApi()
    $scope.toggleInStore = function () {
        $scope.GridLotSearch.inStoreOnly = !$scope.GridLotSearch.inStoreOnly;
        $scope.LotSearchDescription(true);
    };
    $scope.LotSearchDescription = function (resetPagination = false) {
        if (resetPagination) {
            $scope.GridLotSearch.paginationPageNumber = 1;
        }
        // Search Fields + Validation
        const description = $scope.GridLotSearch.description;
        const inStoreOnly = $scope.GridLotSearch.inStoreOnly;
        if (!description) {
            return toaster.info('Please provide a description to search');
        }
        $scope.GridLotSearch.loading = true;
        // Prep xhr params
        const args = {
            search: description,
            inStore: inStoreOnly,
        };
        // pagination
        args.paginate = true;
        args.page = $scope.GridLotSearch.paginationPageNumber;
        args.limit = $scope.GridLotSearch.paginationPageSize;
        // sorting
        const sortColumns = $scope.GridLotSearch.sortColumns;
        if (sortColumns.length) {
            args.order_by = sortColumns
                .map(col => `${col.field},${col.sort.direction}`)
                .join('|');
        }
        else {
            // Sort created_at desc by default
            args.order_by = 'created_at,desc';
        }
        const argString = SvQuery.getEncode(args);
        LotSearchXHR.raw(argString)
            .then(rsp => {
            let dt = rsp.data;
            // Pagination
            $scope.GridLotSearch.paginationPageNumber = dt.current_page;
            $scope.GridLotSearch.paginationPageSize = dt.per_page;
            $scope.GridLotSearch.totalItems = dt.total;
            // Update grid data and remove loading state
            $scope.GridLotSearch.data = _.cloneDeep(dt.data);
            $scope.GridLotSearch.loading = false;
        })
            .catch(err => LotSearchXHR.toastout(err));
    };
    // // STATE
    if ($stateParams.description) {
        $scope.GridLotSearch.description = $stateParams.description;
        $scope.LotSearchDescription();
    }
}); // END CONTROLLER
