angular.module('App')
	.controller('CtrlLot', function (
		$rootScope, $scope, $stateParams, $log, $state, $timeout, $fancyModal, toaster,
		SvStatic, SvXHR, SvQuery, uiGridConstants) {
		// // VARS
		$scope.User = $rootScope.User
		$scope.Def = {
			signed: new Date(),
			created_at: new Date(),
			loading: false
		}
		// Index
		$scope.GridLotIndex = _.cloneDeep($scope.Def)
		const LotStatusList = SvStatic.lotStatus()
		const LotStatusLabel = LotStatusList.map(lot => {
			return {
				'id': lot.id,
				'label': lot.name,
				'value': lot.id
			}
		})
		// Show
		$scope.GridLotSheetShow = _.cloneDeep($scope.Def)
		$scope.LotSheet = _.cloneDeep($scope.Def)
		$scope.Customer = $scope.CustomerDefault()
		const LotXHR = new SvXHR('lots')

		// // FUNCTIONS
		$scope.LotsIndex = function (args: any = {}) {
			$scope.GridLotIndex.loading = true
			// set customer
			args.customer_id = $scope.Customer.id
			// default order
			if (!args.order_by) args.order_by = 'created_at,desc|status,asc'
			// pagination
			args.paginate = true
			args.page = $scope.GridLotIndex.paginationPageNumber
			args.limit = $scope.GridLotIndex.paginationPageSize

			let argString = SvQuery.getEncode(args)

			// hide/show closed
			if ($scope.GridLotIndex.hideClosed) {
				argString = argString + '&' + [
					'nulls=transaction_outgoing_id',
					'status!=5',
					'status!=6',
					'status!=7'
				].join('&')
			}

			LotXHR.raw(argString)
				.then(rsp => {
					let dt = rsp.data
					$log.debug('lots/index results', dt)
					// Pagination
					$scope.GridLotIndex.paginationPageNumber = dt.current_page
					$scope.GridLotIndex.paginationPageSize = dt.per_page
					$scope.GridLotIndex.totalItems = dt.total

					// Apply Checkout Value & Calculation if found
					if (dt.checkoutValue) {
						$scope.GridLotIndex.checkoutValue = dt.checkoutValue.total
						$scope.GridLotIndex.checkoutCalculation = dt.checkoutValue.calculation
					}

					$scope.GridLotIndex.data = _.cloneDeep(dt.data)
					$scope.GridLotIndex.loading = false
				})
				.catch(err => LotXHR.toastout(err))
		}

		// // GRID
		$scope.GridLotIndex = {
			loading: false,
			enableFiltering: true,
			useExternalSorting: true,
			useExternalFiltering: true,
			enableColumnMenus: false,
			enableCellEdit: false,

			useExternalPagination: true,
			paginationPageSizes: [25, 50, 75],
			paginationPageSize: 25,
			paginationPageNumber: 1,
			totalItems: 0,

			enableRowSelection: true,
			multiSelect: true,
			enableSelectAll: false,

			hideClosed: true, // hide closed lots
			checkoutValue: 0, // value of bid results
			checkoutCalculation: false, // tooltip calculation of checkout value

			columnDefs: [{
					name: 'Lot #',
					field: 'visual_id',
					width: 120,
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'Description',
					cellClass: 'text-left pdg-l',
					field: 'description',
					filter: {
						placeholder: ' Search..'
					},
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field]" uib-tooltip="{{row.entity[col.field]}}" tooltip-append-to-body="true" tooltip-placement="top"></div>` //tslint:disable-line
				},
				{
					name: 'Tally',
					field: 'tally_count',
					width: 90,
					cellTemplate: `
					<div class="ui-grid-cell-contents">
						<span ng-if="row.entity[col.field] != 0"
							ng-bind-html="row.entity[col.field]"></span>
						<span ng-if="row.entity[col.field] == 0"> - </span>
					</div>`
				},
				{
					name: 'Reserve',
					field: 'reserve_bid',
					width: 90,
					cellFilter: 'dosh'
				},
				{
					name: 'Status',
					field: 'status',
					width: 100,
					enableSorting: false,
					filter: {
						type: uiGridConstants.filter.SELECT,
						selectOptions: LotStatusLabel
					},
					cellTemplate:`<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | filterLotStatus" ng-class="{ 'bg-success': row.entity[col.field] == 2, 'bg-info': row.entity[col.field] == 3, 'bg-danger': row.entity[col.field] == 4, 'bg-warning': row.entity[col.field] == 8 }"></div>` //tslint:disable-line
				},
				{
					name: 'Paid Cust?',
					field: 'transaction_outgoing_id',
					width: 85,
					enableFiltering: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | filterTick"></div>`
				},
				{
					name: 'Sale Price',
					field: 'price',
					width: 90,
					enableSorting: false,
					cellTemplate: `
						<div class="ui-grid-cell-contents">
							<span ng-if="row.entity['status'] === 2"
								ng-bind-html="row.entity[col.field] | dosh"></span>
							<span ng-if="row.entity['status'] !== 2"> - </span>
						</div>
					`
				},
				{
					name: 'Owing',
					field: 'amount_owing',
					width: 90,
					cellFilter: 'dosh',
					cellTemplate: `
						<div class="ui-grid-cell-contents">
							<span ng-if="row.entity['calculation']"
								ng-bind-html="row.entity['calculation']['total'] | dosh"
								uib-tooltip-html="row.entity['calculation']['calculation']"
								tooltip-append-to-body="true"
								tooltip-placement="top"></span>
							<span ng-if="!row.entity['calculation']"> - </span>
						</div>
					`
				},
				{
					name: 'Collected',
					field: 'collected_at',
					width: 110,
					enableCellEdit: false,
					cellTemplate: `<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'"></div>` //tslint:disable-line
				}
			],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}"
 ng-click="grid.appScope.LotSelected(row)">
</div>`
		}
		$scope.GridLotIndex.onRegisterApi = function (gridApi) {
			$scope.gridLotIndexApi = gridApi
			// // EVENT LISTENERS
			// Pagination
			gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
				$scope.GridLotIndex.paginationPageNumber = newPage
				$scope.GridLotIndex.paginationPageSize = pageSize
				$scope.SorterFilter(this.grid)
			})
			// Filter/Sort via (SorterFilter)
			$scope.gridLotIndexApi.core.on.filterChanged($scope, function () {
				$scope.SorterFilter(this.grid)
			})
			$scope.gridLotIndexApi.core.on.sortChanged($scope, function () {
				$scope.SorterFilter(this.grid)
			})

			// FILTERING + PRIORITIZED SORTING
			$scope.SorterFilter = function (grid, newPage = false, pageSize = false) {
				let queryParams = grid.columns.reduce((sum, col, idx) => {
					if (!sum.sorts) sum.sorts = []
					if (!sum.order_by) sum.order_by = []
					// FILTERS
					if (angular.isDefined(col.filters[0].term) && col.filters[0].term) {
						switch (col.field) {
							// VISUAL ID / STATUS: do not wildcard ID search
							case 'lots-visual_id':
							case 'lot_sheets-visual_id':
							case 'status':
								sum[col.field] = col.filters[0].term
								break
							// wildcard by default
							default:
								sum[col.field] = '\\%' + col.filters[0].term + '\\%'
								break
						}
					}
					// SORTERS
					if (angular.isDefined(col.sort.direction) && col.sort.direction) {
						sum.sorts.push({
							'field': col.field,
							'direction': col.sort.direction,
							'priority': col.sort.priority
						})
						sum.sorts = sum.sorts.sort((a, b) => {
							return a.priority - b.priority
						})
						sum.order_by = sum.sorts.reduce((x, sort) => {
							let tmp = (x === '') ? '' : '|'
							return x + tmp + sort.field + ',' + sort.direction
						}, '')
					}
					if (idx === grid.columns.length - 1) delete sum.sorts
					return sum
				}, {})
				$scope.queryTimeout(queryParams)
			} // END SorterFilter()

			// Query XHR + Debounce
			$scope.queryTimeout = function (queryParams) {
				if (angular.isDefined(this.timer)) $timeout.cancel(this.timer)
				this.timer = $timeout(() => {
					$scope.LotsIndex(queryParams)
				}, 500)
			}
		} // END onRegisterApi()

		$scope.LotSelected = function (row) {
			$state.go('company.lots.update', {
				sheet_id: row.entity['lot_sheet_id']
			})
		}

		$scope.checkoutSoldLots = function () {
			const lotIds = $scope.GridLotIndex.data
        .filter(lot =>
          lot.status === 2
          && !lot.transaction_outgoing_id
          && lot.collected_at
        )
				.map(lot => lot.id)
      if (lotIds.length === 0) {
        return toaster.info('No valid lots for transaction');
      }

			// go to transactions create view
			$state.go('company.transactions.create', {
				'cid': $scope.Customer.id,
				'lid': lotIds.join(','),
				'type': 2 // outgoing payment (vendor)
			})
		}

	}) // END CONTROLLER
