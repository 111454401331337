'use strict'

angular.module('App')
	// converts timestamp to local ISO and outputs long human-readable date
	.directive('isoPretty', function ($filter) {
		return {
			require: '^ngModel',
			scope: true,
			link: function (scope, el, attrs, ctrl) {
				// optional date format (eg iso-pretty="dd MMMM yyyy")
				let format = (attrs.isoPretty) ? attrs.isoPretty : 'dd MMMM yyyy, h:mm a'

				function formatter(value) {
					let iso = $filter('tsToISO')(value)
					let pretty = $filter('date')(iso, format)
					el.val(pretty)

					ctrl.$setViewValue(value)

					if (!scope.$$phase) scope.$apply()

					return pretty
				}
				ctrl.$formatters.push(formatter)

				el.bind('change', function () {
					formatter(el.val())
				})
			}
		}
	})
