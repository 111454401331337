angular.module('App')
	.controller('CtrlCustomer', function (
		$rootScope, $scope, $stateParams, $log, $state, $timeout, $fancyModal, toaster,
		SvXHR) {
		// // VARS
		const CustomerXHR = new SvXHR('customers')
		$scope.User = $rootScope.User
		$scope.Customer = $scope.CustomerDefault()
		// Index
		$scope.GridCustomers = {
			'loading': false
		}

		// // DEFAULTS
		if ($scope.Company && $scope.Company.address_state)
			$scope.Customer.address_state = $scope.Company.address_state

		// // FUNCTIONS
		$scope.CustomersLoad = function (args: any = false) {
			$scope.GridCustomers.loading = true
			// default argument
			if (!args) args = {
				'order_by': 'updated_at,desc'
			}
			// pagination
			args.paginate = true
			args.page = $scope.GridCustomers.paginationPageNumber
			args.limit = $scope.GridCustomers.paginationPageSize

			CustomerXHR.index(args)
				.then(rsp => {
					let dt = rsp.data
					$log.debug('customers/index results', dt)
					// Pagination
					$scope.GridCustomers.paginationPageNumber = dt.current_page
					$scope.GridCustomers.paginationPageSize = dt.per_page
					$scope.GridCustomers.totalItems = dt.total

					$scope.GridCustomers.data = _.cloneDeep(dt.data)
					$scope.GridCustomers.loading = false
				})
				.catch(err => CustomerXHR.toastout(err))
		}

		// // GRID
		$scope.GridCustomers = {
			loading: false,
			enableFiltering: true,
			useExternalSorting: true,
			useExternalFiltering: true,
			enableColumnMenus: false,
			enableCellEdit: false,

			useExternalPagination: true,
			paginationPageSizes: [25, 50, 75],
			paginationPageSize: 25,
			paginationPageNumber: 1,
			totalItems: 0,

			columnDefs: [{
					name: 'CID',
					field: 'id',
					width: 100,
					enableSorting: false,
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'First Name',
					field: 'name_first',
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'Last Name',
					field: 'name_last',
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'Email',
					field: 'email',
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'Mobile',
					field: 'phone_mobile',
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'Postcode',
					field: 'address_postcode',
					filter: {
						placeholder: ' Search..'
					}
				}
			],
			data: [],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}"
 ng-click="grid.appScope.CustomerSelected(row)">
</div>`
		}
		$scope.GridCustomers.onRegisterApi = function (gridApi) {
			$scope.gridCustomersApi = gridApi
			// // EVENT LISTENERS
			// Cell Edit
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				$log.debug('row:', rowEntity, ' colDef:', colDef, ' newVal:', newVal, ' oldVal:', oldVal)
				$scope.$apply()
			})
			// Pagination
			gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
				$scope.GridCustomers.paginationPageNumber = newPage
				$scope.GridCustomers.paginationPageSize = pageSize
				$scope.SorterFilter(this.grid)
			})
			// Filter/Sort via (SorterFilter)
			$scope.gridCustomersApi.core.on.filterChanged($scope, function () {
				$scope.SorterFilter(this.grid)
			})
			$scope.gridCustomersApi.core.on.sortChanged($scope, function () {
				$scope.SorterFilter(this.grid)
			})

			// PAGINATION + FILTERING + PRIORITIZED SORTING
			$scope.SorterFilter = function (grid) {
				let queryParams = grid.columns.reduce((sum, col, idx) => {
					if (!sum.sorts) sum.sorts = []
					if (!sum.order_by) sum.order_by = []
					// FILTERS
					if (angular.isDefined(col.filters[0].term) && col.filters[0].term) {
						// do not wildcard ID search
						if (col.field === 'id') sum[col.field] = col.filters[0].term
						else sum[col.field] = '*' + col.filters[0].term + '*'
					}
					// SORTERS
					if (angular.isDefined(col.sort.direction) && col.sort.direction) {
						sum.sorts.push({
							'field': col.field,
							'direction': col.sort.direction,
							'priority': col.sort.priority
						})
						sum.sorts = sum.sorts.sort((a, b) => {
							return a.priority - b.priority
						})
						sum.order_by = sum.sorts.reduce((x, sort) => {
							let tmp = (x === '') ? '' : '|'
							return x + tmp + sort.field + ',' + sort.direction
						}, '')
					}
					if (idx === grid.columns.length - 1) delete sum.sorts
					return sum
				}, {})
				$scope.queryTimeout(queryParams)
			} // END SorterFilter()

			// Query XHR + Debounce
			$scope.queryTimeout = function (queryParams) {
				if (angular.isDefined(this.timer)) $timeout.cancel(this.timer)
				this.timer = $timeout(() => {
					$scope.CustomersLoad(queryParams)
				}, 500)
			}
		} // END onRegisterApi()

		$scope.CustomerSelected = row => $state.go('company.customers.update', {
			id: row.entity.id
		})

		// // CLEANUP
		$scope.$on('$destroy', function (event) {
			if (angular.isDefined($scope.queryTimeout)) {
				$timeout.cancel($scope.queryTimeout)
			}
		})
	}) // END CONTROLLER
