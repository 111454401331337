'use strict'

angular.module('App')
	.controller('CtrlPolice', function ($rootScope, $scope, $log, $fancyModal, toaster,
		uiGridConstants, SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.Created = {
			'from': moment().subtract(1, 'months'),
			'to': moment().add(1, 'days')
		}
		$scope.reportLoaded = false
		$scope.reportData = ''

		// // FUNCTIONS
		$scope.loadReport = function () {
			const from = moment($scope.Created.from).format('YYYY-MM-DD')
			const to = moment($scope.Created.to).format('YYYY-MM-DD')
			const ReportingXHR = new SvXHR(`reporting/police/${from}/${to}`)
			ReportingXHR.index()
				.then(rsp => {
					$scope.reportData = rsp.data
					$scope.reportLoaded = true
					let iframe: any = document.createElement('iframe')
					iframe.srcdoc = rsp.data
					iframe.src = 'data:text/html;charset=utf-8,' + escape(rsp.data)
					iframe.setAttribute('id', 'reportFrame')
					iframe.setAttribute('width', '100%')
					iframe.setAttribute('height', '500px')
					const iFrameWrapper = document.getElementById('iFrameWrapper')
					while (iFrameWrapper.firstChild) iFrameWrapper.removeChild(iFrameWrapper.firstChild)
					iFrameWrapper.appendChild(iframe)
				})
				.catch(err => ReportingXHR.toastout(err))
		}

		$scope.printReport = function () {
			const reportFrame: any = document.getElementById('reportFrame')
			reportFrame.contentWindow.print()
		}

		// INITIALISE
	}) // END CONTROLLER
