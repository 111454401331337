"use strict";
(function () {
    'use strict';
    angular
        .module('App', [
        'ENV', 'ngRaven', 'ui.router', 'ui.bootstrap', 'satellizer', 'angular-loading-bar',
        'ngSanitize', 'ngAnimate', 'ngStorage',
        'pathgather.popeye', 'vesparny.fancyModal', 'toaster', 'datePicker', 'ngCsv',
        'ui.grid', 'ui.grid.edit', 'ui.grid.rowEdit', 'ui.grid.cellNav',
        'ui.grid.selection', 'ui.grid.pagination'
    ])
        .config([
        'API_AUTH', 'PREFIX', '$stateProvider', '$urlRouterProvider', '$locationProvider',
        '$compileProvider', '$authProvider', '$localStorageProvider', 'PopeyeProvider', 'toasterConfig',
        function (API_AUTH, PREFIX, $stateProvider, $urlRouterProvider, $locationProvider, $compileProvider, $authProvider, $localStorageProvider, PopeyeProvider, toasterConfig) {
            // Satellizer JWT
            $authProvider.tokenPrefix = PREFIX;
            $authProvider.loginUrl = API_AUTH;
            // Basic Config
            $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|mailto|content|file|data):/);
            angular.extend(toasterConfig, {
                'time-out': 5000,
                'progressBar': true,
                'toast-class': 'toast-custom',
                'position-class': 'toast-bottom-right'
            });
            PopeyeProvider.defaults.containerClass = 'modal-container-large';
            $locationProvider.hashPrefix('!');
            $localStorageProvider.setKeyPrefix(PREFIX + '-');
            $urlRouterProvider.otherwise('login');
            $stateProvider
                .state('login', {
                url: '/login?m',
                controller: 'CtrlLogin',
                templateUrl: 'html/company/login/login.html',
                data: {
                    authenticate: false
                }
            })
                .state('company', {
                url: '/',
                abstract: true,
                controller: 'CtrlCompanyApp',
                templateUrl: 'html/company/app/app.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.dashboard', {
                url: 'dashboard',
                controller: 'CtrlDashboard',
                templateUrl: 'html/company/dashboard/dashboard.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.customers', {
                url: 'customers',
                abstract: true,
                template: '<ui-view/>',
                controller: 'CtrlCustomer',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.customers.index', {
                url: '/index?q&f',
                templateUrl: 'html/company/customers/index.html',
                controller: 'CtrlCustomerIndex',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.customers.update', {
                url: '/update?id',
                templateUrl: 'html/company/customers/update.html',
                controller: 'CtrlCustomerUpdate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.customers.create', {
                url: '/create',
                templateUrl: 'html/company/customers/update.html',
                controller: 'CtrlCustomerUpdate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.customers.send_bulk_emails', {
                url: '/send_bulk_emails',
                templateUrl: 'html/company/customers/send_bulk_emails.html',
                controller: 'CtrlSendBulkEmails',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.bids', {
                url: 'bids',
                abstract: true,
                template: '<ui-view/>',
                controller: 'CtrlBid',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.bids.index', {
                url: '/index?cid',
                templateUrl: 'html/company/bids/index.html',
                controller: 'CtrlBidIndex',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.bids.update', {
                url: '/update?sheet_id',
                templateUrl: 'html/company/bids/bidsheet-update.html',
                controller: 'CtrlBidUpdate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.bids.create', {
                url: '/create',
                templateUrl: 'html/company/bids/bidsheet-update.html',
                controller: 'CtrlBidUpdate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.lots', {
                url: 'lots',
                template: '<ui-view/>',
                abstract: true,
                controller: 'CtrlLot',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.lots.index', {
                url: '/index?cid',
                templateUrl: 'html/company/lots/index.html',
                controller: 'CtrlLotIndex',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.lots.search', {
                url: '/search?description',
                templateUrl: 'html/company/lots/search.html',
                controller: 'CtrlLotSearch',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.lots.update', {
                url: '/update?sheet_id',
                templateUrl: 'html/company/lots/lotsheet-update.html',
                controller: 'CtrlLotUpdate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.lots.create', {
                url: '/create',
                templateUrl: 'html/company/lots/lotsheet-update.html',
                controller: 'CtrlLotUpdate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.contact', {
                url: 'contact',
                template: '<ui-view/>',
                abstract: true,
                controller: 'CtrlContact',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.contact.sellers', {
                url: '/sellers',
                controller: 'CtrlSellers',
                templateUrl: 'html/company/contact/sellers.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.contact.bidders', {
                url: '/bidders',
                controller: 'CtrlBidders',
                templateUrl: 'html/company/contact/bidders.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.transactions', {
                url: 'transactions',
                template: '<ui-view/>',
                abstract: true,
                controller: 'CtrlTransaction',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.transactions.index', {
                url: '/index?cid',
                templateUrl: 'html/company/transactions/index.html',
                controller: 'CtrlTransactionIndex',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.transactions.create', {
                url: '/create?cid&lid&type',
                templateUrl: 'html/company/transactions/create.html',
                controller: 'CtrlTransactionCreate',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.tally', {
                url: 'tally',
                controller: 'CtrlTally',
                templateUrl: 'html/company/tally/tally.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.settings', {
                url: 'settings',
                controller: 'CtrlSettings',
                templateUrl: 'html/company/settings/settings.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting', {
                url: 'reporting',
                template: '<ui-view/>',
                abstract: true,
                controller: 'CtrlReporting',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.bidder_results', {
                url: '/bidder_results',
                controller: 'CtrlBidderResults',
                templateUrl: 'html/company/reporting/bidder_results/bidder_results.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.bids_current', {
                url: '/bids_current',
                controller: 'CtrlBidsCurrent',
                templateUrl: 'html/company/reporting/bids_current/bids_current.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.cash', {
                url: '/cash',
                controller: 'CtrlCash',
                templateUrl: 'html/company/reporting/cash/cash.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.creditors', {
                url: '/creditors',
                controller: 'CtrlCreditors',
                templateUrl: 'html/company/reporting/creditors/creditors.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.eft', {
                url: '/eft',
                controller: 'CtrlEft',
                templateUrl: 'html/company/reporting/eft/eft.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.police', {
                url: '/police',
                controller: 'CtrlPolice',
                templateUrl: 'html/company/reporting/police/police.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.revenue', {
                url: '/revenue',
                controller: 'CtrlRevenue',
                templateUrl: 'html/company/reporting/revenue/revenue.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.catalogue', {
                url: '/catalogue',
                controller: 'CtrlCatalogue',
                templateUrl: 'html/company/reporting/catalogue/catalogue.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.seller_results', {
                url: '/seller_results',
                controller: 'CtrlSellerResults',
                templateUrl: 'html/company/reporting/seller_results/seller_results.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.sold_instore', {
                url: '/sold_instore',
                controller: 'CtrlSoldInstore',
                templateUrl: 'html/company/reporting/sold_instore/sold_instore.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.uncollected', {
                url: '/uncollected',
                controller: 'CtrlUncollected',
                templateUrl: 'html/company/reporting/uncollected/uncollected.html',
                data: {
                    authenticate: 'staff'
                }
            })
                .state('company.reporting.underbids', {
                url: '/underbids',
                controller: 'CtrlUnderbids',
                templateUrl: 'html/company/reporting/underbids/underbids.html',
                data: {
                    authenticate: 'staff'
                }
            });
        }
    ])
        .run([
        '$window', '$rootScope', '$urlRouter', '$http', '$auth', '$state',
        '$stateParams', '$q', '$localStorage', '$fancyModal',
        function ($window, $rootScope, $urlRouter, $http, $auth, $state, $stateParams, $q, $localStorage, $fancyModal) {
            // // VARS
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            const SearchDef = {
                'type': 2,
                'terms': '',
                'loading': false
            };
            $rootScope.Search = _.cloneDeep(SearchDef);
            $rootScope.Loading = false;
            // // LOADING MODAL
            $rootScope.Loading = false;
            $rootScope.LoadingModal = function () {
                if (!$rootScope.Loading) {
                    $fancyModal.open({
                        templateUrl: 'html/common/modals/loading.html',
                        themeClass: 'modal-loading',
                        closeOnEscape: false,
                        closeOnOverlayClick: false,
                        showCloseButton: false,
                        locked: false
                    });
                }
            };
            $rootScope.$on('$fancyModal.opened', function (e, $modal) {
                $rootScope.Loading = true;
            });
            $rootScope.$on('$fancyModal.closed', function (e, id) {
                $rootScope.Loading = false;
            });
            // END LOADING MODAL
            // AUTH MODULE - JWT based
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                // Auth-enabled state
                if (toState.data.authenticate) {
                    // Verify auth
                    if (!$auth.isAuthenticated()) {
                        $state.transitionTo('login');
                        event.preventDefault();
                    }
                    else {
                        // Authenticated but not stored
                        if (!$rootScope.User) {
                            $rootScope.User = $auth.getPayload().user;
                            $rootScope.Version = $auth.getPayload().version;
                            $rootScope.User.dob = moment($rootScope.User.dob).toDate();
                        }
                    }
                }
            });
            // END AUTH MODULE
        }
    ]); // END App.run()
})(); // END IIFE
