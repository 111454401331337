"use strict";
angular.module('App')
    .controller('CtrlBid', function ($rootScope, $scope, $stateParams, $log, $state, $timeout, $filter, $fancyModal, toaster, SvXHR, SvStatic, uiGridConstants) {
    // // VARS
    $scope.User = $rootScope.User;
    $scope.Def = {
        signed: new Date(),
        created_at: new Date(),
        loading: false
    };
    const BidStatusList = SvStatic.bidStatus().filter(bid => (bid.id > 1 && bid.id < 5));
    const BidStatusLabel = BidStatusList.map(bid => {
        return {
            'id': bid.id,
            'label': bid.name,
            'value': bid.id
        };
    });
    // Index
    $scope.GridBidIndex = _.cloneDeep($scope.Def);
    // Show
    $scope.GridBidSheetShow = _.cloneDeep($scope.Def);
    $scope.BidSheet = _.cloneDeep($scope.Def);
    $scope.Customer = $scope.CustomerDefault();
    const BidXHR = new SvXHR('bids');
    // // FUNCTIONS
    $scope.BidsIndex = function (args = {}) {
        $scope.GridBidIndex.loading = true;
        // set customer
        args.customer_id = $scope.Customer.id;
        // default order
        if (!args.order_by)
            args.order_by = 'created_at,desc|status,asc';
        args.includes = 'lot.bids';
        // pagination
        args.paginate = true;
        args.page = $scope.GridBidIndex.paginationPageNumber;
        args.limit = $scope.GridBidIndex.paginationPageSize;
        args.hide_closed_bids = $scope.GridBidIndex.hideClosed;
        BidXHR.index(args)
            .then(rsp => {
            let dt = rsp.data;
            $log.debug('bids/index results', dt);
            // Pagination
            $scope.GridBidIndex.paginationPageNumber = dt.current_page;
            $scope.GridBidIndex.paginationPageSize = dt.per_page;
            $scope.GridBidIndex.totalItems = dt.total;
            // Apply Checkout Value & Calculation if found
            if (dt.checkoutValue) {
                $scope.GridBidIndex.checkoutValue = dt.checkoutValue.total;
                $scope.GridBidIndex.checkoutCalculation = dt.checkoutValue.calculation;
            }
            // Underbids: determine highest bid
            dt.data = dt.data.map(bid => {
                // assign tooltip value to all status (otherwise undefined value in grid)
                bid.tooltipStatus = $filter('filterBidStatus')(bid.status);
                if (bid.status === 4) {
                    // determine highest bid
                    let highestBid = bid;
                    for (let lotBid of bid.lot.bids)
                        if (lotBid.bid_value > highestBid.bid_value)
                            highestBid = lotBid;
                    bid.highest_bid = highestBid;
                    // set tooltip to highest bid value
                    bid.tooltipStatus = 'Highest Bid: '
                        + $filter('dosh')(highestBid.bid_value)
                        + '\n Your Bid: '
                        + $filter('dosh')(bid.bid_value);
                }
                return bid;
            });
            $scope.GridBidIndex.data = _.cloneDeep(dt.data);
            $scope.GridBidIndex.loading = false;
        })
            .catch(err => BidXHR.toastout(err));
    };
    // // GRID
    $scope.GridBidIndex = {
        loading: false,
        enableFiltering: true,
        useExternalSorting: true,
        useExternalFiltering: true,
        enableColumnMenus: false,
        enableCellEdit: false,
        useExternalPagination: true,
        paginationPageSizes: [50, 75, 100],
        paginationPageSize: 50,
        paginationPageNumber: 1,
        totalItems: 0,
        hideClosed: true,
        checkoutValue: 0,
        checkoutCalculation: false,
        columnDefs: [{
                name: 'Date',
                field: 'created_at',
                width: 150,
                cellFilter: 'tsToISO | date : \'MMM d, y\'',
                filter: {
                    placeholder: ' Search..'
                }
            },
            {
                name: 'Lot #',
                field: 'lot.visual_id',
                width: 100,
                enableFiltering: false,
                enableSorting: false
            },
            {
                name: 'Lot Description',
                cellClass: 'text-left pdg-l',
                field: 'lot.description',
                enableFiltering: false,
                enableSorting: false
            },
            {
                name: 'Bid Status',
                field: 'status',
                width: 100,
                enableSorting: false,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: BidStatusLabel
                },
                cellTemplate: `
<div class="ui-grid-cell-contents"
 ng-bind-html="row.entity[col.field] | filterBidStatus"
 uib-tooltip="{{row.entity['tooltipStatus']}}"
 tooltip-append-to-body="true"
 tooltip-placement="top"
 ng-class="{
'grass-light-bg' : row.entity.status == 4 && row.entity.highest_bid.id == row.entity.id
}">
</div>`
            },
            {
                name: 'Paid?',
                field: 'transaction_incoming_id',
                width: 60,
                enableFiltering: false,
                cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | filterTick"></div>`
            },
            {
                name: 'Bid Value',
                field: 'lot.price',
                cellFilter: 'dosh',
                width: 100,
                enableSorting: false,
                cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity.bid_value | dosh"></div>`
            },
            {
                name: 'Reserve Price',
                field: 'lot.price',
                cellFilter: 'dosh',
                width: 100,
                enableSorting: false,
                cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity.lot.reserve_bid | dosh"></div>`
            },
            {
                name: 'Sale Price',
                field: 'lot.price',
                cellFilter: 'dosh',
                width: 100,
                enableSorting: false,
                cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity.lot.price | dosh"></div>`
            }
        ],
        rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}"
 ng-click="grid.appScope.BidSelected(row)">
</div>`
    };
    $scope.GridBidIndex.onRegisterApi = function (gridApi) {
        $scope.gridBidIndexApi = gridApi;
        // // EVENT LISTENERS
        // Pagination
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
            $scope.GridBidIndex.paginationPageNumber = newPage;
            $scope.GridBidIndex.paginationPageSize = pageSize;
            $scope.SorterFilter(this.grid);
        });
        $scope.gridBidIndexApi.core.on.filterChanged($scope, function () {
            $scope.SorterFilter(this.grid);
        });
        $scope.gridBidIndexApi.core.on.sortChanged($scope, function () {
            $scope.SorterFilter(this.grid);
        });
        // FILTERING + PRIORITIZED SORTING
        $scope.SorterFilter = function (grid) {
            let queryParams = grid.columns.reduce((sum, col, idx) => {
                if (!sum.sorts)
                    sum.sorts = [];
                if (!sum.order_by)
                    sum.order_by = [];
                // FILTERS
                if (angular.isDefined(col.filters[0].term) && col.filters[0].term) {
                    // do not wildcard: visuaL_id or status
                    if (col.field === 'visual_id' || col.field === 'status')
                        sum[col.field] = col.filters[0].term;
                    else
                        sum[col.field] = '\\%' + col.filters[0].term + '\\%';
                }
                // SORTERS
                if (angular.isDefined(col.sort.direction) && col.sort.direction) {
                    sum.sorts.push({
                        'field': col.field,
                        'direction': col.sort.direction,
                        'priority': col.sort.priority
                    });
                    sum.sorts = sum.sorts.sort((a, b) => {
                        return a.priority - b.priority;
                    });
                    sum.order_by = sum.sorts.reduce((x, sort) => {
                        let tmp = (x === '') ? '' : '|';
                        return x + tmp + sort.field + ',' + sort.direction;
                    }, '');
                }
                if (idx === grid.columns.length - 1)
                    delete sum.sorts;
                return sum;
            }, {});
            $scope.queryTimeout(queryParams);
        }; // END SorterFilter()
        // Query XHR + Debounce
        $scope.queryTimeout = function (queryParams) {
            if (angular.isDefined(this.timer))
                $timeout.cancel(this.timer);
            this.timer = $timeout(() => {
                $scope.BidsIndex(queryParams);
            }, 500);
        };
    }; // END onRegisterApi()
    $scope.BidSelected = function (row) {
        $state.go('company.bids.update', {
            sheet_id: row.entity['bid_sheet_id']
        });
    };
    $scope.checkoutWonBids = function () {
        const lotIds = $scope.GridBidIndex.data
            .filter(bid => bid.status === 2 && !bid.transaction_incoming_id)
            .map(bid => bid.lot.id);
        if (lotIds.length === 0)
            return toaster.info('No valid bids selected for transaction');
        // go to transactions create view
        $state.go('company.transactions.create', {
            'cid': $scope.Customer.id,
            'lid': lotIds.join(','),
            'type': 1 // incoming payment (bidder)
        });
    };
    // // CLEANUP
    $scope.$on('$destroy', function (event) {
        if (angular.isDefined($scope.queryTimeout)) {
            $timeout.cancel($scope.queryTimeout);
        }
    });
}); // END CONTROLLER
