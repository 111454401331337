"use strict";
angular.module('App')
    .controller('CtrlTransactionIndex', function ($rootScope, $scope, $state, $stateParams, $log) {
    // // STATE
    if ($stateParams.cid) {
        $scope.Transaction.customer_id = $stateParams.cid;
        $scope.TransactionIndex({
            limit: 500
        });
    }
    else {
        $state.go('company.dashboard');
    }
}); // END CONTROLLER
