'use strict'

angular.module('App')
	.controller('CtrlUncollected', function ($rootScope, $scope, $log, $fancyModal, toaster,
		uiGridConstants, SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.GridUncollected = {}
		$scope.Created = {
			'from': moment().subtract(1, 'months'),
			'to': moment().add(1, 'days')
		}
		const LotStatusList = SvStatic.lotStatus()
		const LotStatusLabel = LotStatusList
			.map(lot => {
				return {
					'id': lot.id,
					'label': lot.name,
					'value': lot.id
				}
			})
			.filter(lot => lot.value !== 4)
		const LotXHR = new SvXHR('lots')

		// // FUNCTIONS
		$scope.UncollectedLoad = function () {
			LotXHR.index({
					'nulls': 'collected_at',
					/* eslint-disable no-dupe-keys */
					// tslint:disable:duplicate-identifier
					// 1 Open, 3 Underbid, 4 Expired, 5 Returned, 6 Disposed
					// 'status!': 1, 'status!': 3, 'status!': 4, 'status!': 5,
					'status!': 6, // Disposed
					/* eslint-enable no-dupe-keys */
					'includes': 'customer,bids.customer',
					'order_by': 'created_at,desc',
					'limit': 'unlimited',
					'created_at>': moment($scope.Created.from).toISOString(),
					'created_at<': moment($scope.Created.to).toISOString()
				})
				.then(rsp => {
					$scope.GridUncollected.data = rsp.data
						.map(lot => {
							// add winning bidder if exists
							if (lot.status === 2) {
								let winnerIdx = lot.bids.findIndex(el => el.status === 2)
								if (winnerIdx !== -1) lot.winning_bidder = lot.bids[winnerIdx].customer
							}
							return lot
						})
					$scope.GridUncollected.loading = false
				})
				.catch(err => LotXHR.toastout(err))
		}

		$scope.LotUpdate = lot => {
			LotXHR.update(lot)
				.then(rsp => toaster.success('Lot Updated!'))
				.catch(err => LotXHR.toastout(err))
		}

		$scope.CollectedToggle = row => {
			if (row.entity.collected_at) delete row.entity.collected_at
			else row.entity.collected_at = moment().toISOString()
			$scope.LotUpdate(row.entity)
		}

		// // GRID
		$scope.GridUncollected = {
			loading: false,
			enableCellEdit: true,
			enableFiltering: true,
			enableColumnMenus: false,
			columnDefs: [{
					name: 'Date',
					field: 'created_at',
					width: 150,
					cellFilter: 'tsToISO | date : \'MMM dd, y\'',
					enableFiltering: false,
					enableSorting: true
				},
				{
					name: 'Seller Name',
					field: 'customer',
					width: 200,
					cellClass: 'text-left pdg-l',
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents">{{row.entity[col.field].name_first}} {{row.entity[col.field].name_last}}</div>` // tslint:disable
				},
				{
					name: 'Buyer Name',
					field: 'winning_bidder',
					width: 200,
					cellClass: 'text-left pdg-l',
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-if="row.entity['winning_bidder']">
<a href class="text-primary" ng-click="grid.appScope.$state.go('company.bids.index',{'cid':row.entity.winning_bidder.id})">{{row.entity['winning_bidder'].name_first}} {{row.entity['winning_bidder'].name_last}}</a>
</div>
<div class="ui-grid-cell-contents" ng-if="!row.entity['winning_bidder']"> - </div>`
				},
				{
					name: 'Lot Description',
					field: 'description',
					minWidth: 100,
					cellClass: 'text-left pdg-l',
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Collected',
					field: 'collected_at',
					width: 100,
					enableColumnMenu: true,
					enableHiding: false,
					menuItems: [{
							title: 'Select All',
							icon: 'ui-grid-icon-info-circled',
							action: function ($event) {
								$scope.GridUncollected.data.filter((lot) => {
									if (lot.id) lot.collected_at = moment().toISOString()
									return lot
								})
							},
							context: $scope
						},
						{
							title: 'Deselect All',
							icon: 'ui-grid-icon-cancel',
							action: function ($event) {
								$scope.GridUncollected.data.filter((lot) => delete lot.collected_at)
							},
							context: $scope
						}
					],
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'" ng-click="grid.appScope.CollectedToggle(row)"></div>
`
				},
				{
					name: 'Status',
					field: 'status',
					width: 150,
					cellFilter: 'filterLotStatus',
					filter: {
						term: '',
						type: uiGridConstants.filter.SELECT,
						selectOptions: LotStatusLabel,
						placeholder: ' Search..'
					},
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: LotStatusList
				}
			],
			data: [{
				'name': 'Loading..'
			}],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}">
</div>`
		}

		$scope.GridUncollected.onRegisterApi = function (gridApi) {
			$scope.gridUncollectedApi = gridApi
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				$log.debug('row:', rowEntity, ' colDef:', colDef, ' newVal:', newVal, ' oldVal:', oldVal)
				$scope.$apply()
				if (newVal !== oldVal) $scope.LotUpdate(rowEntity)
			})
		}

		// INITIALISE
		$scope.UncollectedLoad()
	}) // END CONTROLLER
