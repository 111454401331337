'use strict'

angular.module('App').factory('SvQuery', function ($rootScope, $log) {
	return {
		// encodes query parameters into html-safe characters for GET requests (handles nested object too)
		getEncode: function getEncode(obj, prefix = false) {
			let str = []
			for (let prop in obj) {
				if (obj.hasOwnProperty(prop)) {
					let key: any = prefix ? prefix + '[' + prop + ']' : prop,
						val = obj[prop]
					str.push(typeof val === 'object'
						? getEncode(val, key)
						: encodeURIComponent(key) + '=' + encodeURIComponent(val))
				}
			}
			return str.filter((el) => {
				return el !== ''
			}).join('&')
		},
		// traverses object and deletes any null properties or _by tags
		clearNullsAuthors: function (obj) {
			const authors = ['created_by', 'updated_by', 'deleted_by']

			function* nullRecurse(data) {
				// if null, end loop
				if (data == null) {
					return
				}

				// string, number, etc
				if (typeof data !== 'object') yield

				// objects
				if (Array.isArray(data)) {
					// array
					for (let [ind, el] of data.entries()) {
						// loop recursive if object, delete if null/author, else continue
						if (typeof data[ind] === 'object') yield* nullRecurse(data[ind])
						if (data[ind] == null || authors.indexOf(String(ind)) !== -1) data.splice(ind, 1)
						yield
					}
				} else {
					// object
					for (let el in data) {
						if (!data.hasOwnProperty(el))
							continue
						// loop recursive if object, delete if null/author, else continue
						if (typeof data[el] === 'object')
							yield* nullRecurse(data[el])
						if (data[el] == null || authors.indexOf(el) !== -1) delete data[el]
						yield
					}
				}
			} // END nullRecurse function

			// instantiate recursive fn and iterator
			let it = nullRecurse(obj)
			let res = it.next()

			// loop until return found (vs yielf)
			while (!res.done) {
				res = it.next()
			}

			return obj
		}

	}
})
