'use strict'

angular.module('App')
.controller('CtrlCash', function ($rootScope, $scope, $log, $fancyModal, toaster, uiGridConstants, SvStatic, SvQuery, SvXHR) {
  // // VARS
  const TransactionXHR = new SvXHR('transactions');
  $scope.Inputs = {
    created_from: moment(),
    created_to: moment().add(1, 'days'),
    cob: 0,
    d_lost_stock_adjustments: 0,
  }
  const CashValuesInitial: CashValues = {
    a_receipts: 0,
    b_payments: 0,
    c_refunds: 0,
    d_lost_stock_adjustments: 0,
    e_subtotal: 0,
    f_total: 0,
  }
  $scope.CashValues = CashValuesInitial;

  const EftValuesInitial: EftValues = {
    total: 0,
    refunds: 0,
    incoming: 0,
    outgoing: 0,
  }
  $scope.EftValues = EftValuesInitial;

  // // FUNCTIONS
  $scope.UpdateInputs = async function() {
    const cob = $scope.Inputs.cob;
    const queryPacket: any = {
      'limit': 'unlimited',
      'created_at>': moment($scope.Inputs.created_from).toISOString(),
      'created_at<': moment($scope.Inputs.created_to).toISOString()
    }
    // XHR
    try {
      const rsp = await TransactionXHR.index(queryPacket);
      const data = rsp.data;
      // Cash
      const cashTxns = data.filter(t => t.payment_method === TxnPaymentMethodEnum.CASH);
      const a_receipts = cashTxns
        .filter(t => t.type === PaymentTypeEnum.INCOMING_PAYMENT)
        .map(t => t.total || 0)
        .reduce((sum, t) => sum + t, 0);
      const b_payments = cashTxns
        .filter(t => t.type === PaymentTypeEnum.OUTGOING_PAYMENT)
        .map(t => t.total || 0)
        .reduce((sum, t) => sum + t, 0);
      const c_refunds = cashTxns
        .filter(t => t.type === PaymentTypeEnum.REFUND)
        .map(t => t.total || 0)
        .reduce((sum, t) => sum + t, 0);
      const d_lost_stock_adjustments = $scope.Inputs.d_lost_stock_adjustments;
      const e_subtotal = b_payments + c_refunds + d_lost_stock_adjustments;
      const f_total = cob + a_receipts - e_subtotal;
      const CashValues: CashValues = {
        a_receipts,
        b_payments,
        c_refunds,
        d_lost_stock_adjustments,
        e_subtotal,
        f_total,
      };
      $scope.CashValues = CashValues;
      // Eftpos
      const eftTxns = data.filter(t => t.payment_method === TxnPaymentMethodEnum.EFTPOS);
      const eftIncoming = eftTxns
        .filter(t => t.type === PaymentTypeEnum.INCOMING_PAYMENT)
        .map(t => t.total || 0)
        .reduce((sum, t) => sum + t, 0);
      const eftOutgoing = eftTxns
        .filter(t => t.type === PaymentTypeEnum.OUTGOING_PAYMENT)
        .map(t => t.total || 0)
        .reduce((sum, t) => sum + t, 0);
      const eftRefunds = eftTxns
        .filter(t => t.type === PaymentTypeEnum.REFUND)
        .map(t => t.total || 0)
        .reduce((sum, t) => sum + t, 0);
      const eftTotal = eftIncoming - eftOutgoing - eftRefunds;
      const EftValues: EftValues = {
        total: eftTotal,
        refunds: eftRefunds,
        incoming: eftIncoming,
        outgoing: eftOutgoing,
      };
      $scope.EftValues = EftValues;
    } catch (err) {
      return TransactionXHR.toastout(err);
    }
  }

  $scope.printPage = function() {
    window.print();
  }

  // INITIALISE
  $scope.UpdateInputs();
}) // END CONTROLLER

enum TxnPaymentMethodEnum {
  CASH = 1,
  EFTPOS = 2,
  FUNDS_TRANSFER = 3
}
type TxnPaymentMethodType = TxnPaymentMethodEnum.CASH | TxnPaymentMethodEnum.EFTPOS | TxnPaymentMethodEnum.FUNDS_TRANSFER;

enum PaymentTypeEnum {
  INCOMING_PAYMENT = 1,
  OUTGOING_PAYMENT = 2,
  REFUND = 3,
  RETURN = 4
}
type PaymentType = PaymentTypeEnum.INCOMING_PAYMENT | PaymentTypeEnum.OUTGOING_PAYMENT | PaymentTypeEnum.REFUND | PaymentTypeEnum.RETURN;

interface CashValues {
  a_receipts: number;
  b_payments: number;
  c_refunds: number;
  d_lost_stock_adjustments: number;
  e_subtotal: number;
  f_total: number;
}
interface EftValues {
  total: number;
  refunds: number;
  incoming: number;
  outgoing: number;
}
