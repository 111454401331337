'use strict'

angular.module('App')
	// handles currency figures involving decimals, commas and whitespace
	// returns integer values in cents
	.directive('smartFloat', function ($filter) {
		const FLOAT_REGEXP_1 = /^\$?\d+.(\d{3})*(\,\d*)$/ // Numbers like: 1.123,56
		const FLOAT_REGEXP_2 = /^\$?\d+,(\d{3})*(\.\d*)$/ // Numbers like: 1,123.56
		const FLOAT_REGEXP_3 = /^\$?\d+(\.\d*)?$/ // Numbers like: 1123.56
		const FLOAT_REGEXP_4 = /^\$?\d+(\,\d*)?$/ // Numbers like: 1123,56

		return {
			require: ['ngModel'],
			link: function (scope, elm, attrs, ctrls) {
				function formatter(viewValue) {
					if (FLOAT_REGEXP_1.test(viewValue)) {
						ctrls[0].$setValidity('float', true)
						return Math.round(parseFloat(viewValue.replace('.', '').replace(',', '.')) * 100)
					} else if (FLOAT_REGEXP_2.test(viewValue)) {
						ctrls[0].$setValidity('float', true)
						return Math.round(parseFloat(viewValue.replace(',', '')) * 100)
					} else if (FLOAT_REGEXP_3.test(viewValue)) {
						ctrls[0].$setValidity('float', true)
						return Math.round(parseFloat(viewValue) * 100)
					} else if (FLOAT_REGEXP_4.test(viewValue)) {
						ctrls[0].$setValidity('float', true)
						return Math.round(parseFloat(viewValue.replace(',', '.')) * 100)
					} else {
						ctrls[0].$setValidity('float', false)
						return undefined
					}
				}

				ctrls[0].$parsers.unshift(function (viewValue) {
					return formatter(viewValue)
				})

				ctrls[0].$formatters.unshift(
					function (modelValue) {
						return $filter('dosh')(modelValue)
					}
				)
			}
		}
	})
	// converts integer to local currency via dosh filter
	.directive('dosh', function ($filter, $log) {
		return {
			require: '^ngModel',
			scope: true,
			link: function (scope, el, attrs, ctrl) {
				function formatter(value) {
					if (typeof value !== 'undefined') {
						// convert to string
						value = value + ''
						// remove decimals if exist
						value = value.replace('.', '')
						// remove commas if exist
						value = value.replace(',', '')
						// zero fill to 2dp (if not negative)
						if (value[0] !== '-')
							for (let i = value.length; i < 3; i++) value = '0' + value
					}
					let dosh = $filter('dosh')(value)
					el.val(dosh)
					ctrl.$setViewValue(parseInt(value, 10))
					if (!scope.$$phase) scope.$apply()
					return dosh
				}
				ctrl.$formatters.unshift(formatter)

				el.bind('change', function () {
					formatter(el.val())
				})
			}
		}
	})
	// converts integer to percentage via percentage filter
	.directive('percentage', function ($filter, $log) {
		return {
			require: '^ngModel',
			scope: true,
			link: function (scope, el, attrs, ctrl) {
				function formatter(value) {
					if (typeof value === 'undefined') value = 0
					value = value + '' // convert to string
					value = value.replace('.', '') // remove decimals if exist
					value = value.replace(',', '') // remove commas if exist
					for (let i = value.length; i < 3; i++) value = value + '0' // zero fill to 2dp
					let percentage = $filter('percentage')(value)
					el.val(percentage)
					ctrl.$setViewValue(parseInt(value, 10))
					if (!scope.$$phase) scope.$apply()
					return percentage
				}
				ctrl.$formatters.push(formatter)

				el.bind('change', function () {
					formatter(el.val())
				})
			}
		}
	})
