'use strict'

angular.module('App')
	.directive('header', function () {
		return {
			templateUrl: 'html/common/header/header.html',
			restrict: 'EA',
			replace: true,
			transclude: true,
			controller: function ($rootScope, $scope, $state, $log, toaster) {
				// // VARIABLES
				$scope.Search = $rootScope.Search

				// // FUNCTIONS
				// Submit
				let SearchQueueTicket = 1 // tracks order of execution
				let SearchQueueCounter = 0 // tracks # of active queries
				$scope.SearchSubmit = function () {
					// Validate
					if (!$scope.Search.terms || $scope.Search.terms === '')
						return toaster.info('Search cannot be empty')

					$scope.Search.loading = true

					// Switch
					switch (parseInt($scope.Search.type, 10)) {
						// bidsheet
						case 1:
							{
								$state.go('company.bids.index', {
									q: $scope.Search.terms
								})
								break
							}
							// customer
						case 2:
							{
								$state.go('company.customers.index', {
									q: $scope.Search.terms
								})
								break
							}
							// lotsheet
						case 3:
							{
								$state.go('company.lots.index', {
									q: $scope.Search.terms
								})
								break
							}
						default:
							break
					}
				} // END Search
			} // END Controller
		} // END Return Directive
	}) // END Directive
