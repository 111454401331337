angular.module('App')
	.controller('CtrlSettings', function ($rootScope, $scope, $log, Popeye, toaster, SvXHR) {
		// // VARS
		let UserXHR = new SvXHR('users')
		let CompanyXHR = new SvXHR('companies')
		let CategoryXHR = new SvXHR('categories')
		let CommissionXHR = new SvXHR('commissions')
		$scope.Company = {
			loading: true
		}
		$scope.Categories = {
			'destroy': {},
			'create': '',
			'loading': true
		}

		// // FUNCTIONS
		$scope.CompanyDetailLoad = () => {
			CompanyXHR.show(0)
				.then(rsp => {
					rsp.data.auction_times = rsp.data.auction_times.map(day => {
						day.day_start = new Date(day.day_start)
						day.day_end = new Date(day.day_end)
						day.day_enabled = !!day.day_enabled
						return day
					})
					$scope.Company = rsp.data
					console.log('scope.Company', $scope.Company)
					$scope.loading = false
				})
				.catch(err => UserXHR.toastout(err))
		}
		$scope.CompanyDetailLoad()

		$scope.UserUpdate = function () {
			let packet: any = {
				'email': $scope.User.email
			}
			if ($scope.User.password) packet.password = $scope.User.password
			UserXHR.update(packet, $scope.User.id)
				.then(rsp => {
					toaster.success('User Details updated!')
				})
				.catch(err => {
					toaster.error('Error updating inforamtion')
					$log.error(err)
				})
		}

		$scope.CompanyUpdate = function (field: any = false) {
			let packet = {}
			let fillable = new Set()
			if (!field) {
				fillable = new Set([
					'name', 'abn', 'business_licence',
					'phone_number', 'phone_mobile', 'website',
					'address', 'address_state', 'sms_tagline', 'minimum_bid', 'terms_conditions',
					'surcharge_rate', 'lot_fee', 'bid_sheet_fee', 'gst_rate',
					'tally_limit'
				])
			} else fillable = new Set(field)

			for (let key of fillable)
				if ($scope.Company[key]) packet[key] = $scope.Company[key]

			CompanyXHR.update(packet, $scope.Company.id)
				.then(rsp => {
					toaster.success('Company Details updated!')
				})
				.catch(err => {
					toaster.error('Error updating information')
					$log.error(err)
				})
		}

		$scope.CommissionAdd = (idx) => {
			const DefaultComm = {
				'company_id': $scope.Company.id,
				'commission_max': 0,
				'commission_rate': 0
			}
			$scope.Company.commissions.splice(idx + 1, 0, DefaultComm)
			return true
		}

		$scope.CommissionRemove = (idx) => {
			$scope.Company.commissions.splice(idx, 1)
			return true
		}

		$scope.CommissionsUpdate = function () {
			CommissionXHR.update($scope.Company.commissions, $scope.Company.id)
				.then(rsp => {
					toaster.success('Commissions updated!')
				})
				.catch(err => {
					toaster.error('Error updating commissions')
					$log.error(err)
				})
			return true
		}

		$scope.CategoryCreate = function () {
			if (!$scope.Categories.create) return toaster.info('Please enter a name for the new category')
			CategoryXHR.create({
					'company_id': $scope.Company.id,
					'name': $scope.Categories.create
				})
				.then(rsp => {
					$scope.Company = rsp.data
					$scope.CompanyDetailLoad()
					$scope.Categories.create = ''
					toaster.success('Category created!')
				})
				.catch(err => {
					toaster.error('Error creating category')
					$log.error(err)
				})
		}

		$scope.CategoryDestroyModal = function () {
			if (!$scope.Categories.destroy || typeof $scope.Categories.destroy !== 'number') {
				return toaster.info('Please select a category to delete')
			}
			Popeye.openModal({
				templateUrl: 'html/common/modals/categories-destroy.html',
				scope: $scope,
				controller: [function () {
					$scope.Close = () => Popeye.closeCurrentModal({
						'name': 'category-delete',
						message: 'closed'
					})
					$scope.CategoryDestroy = () => {
						CategoryXHR.destroy({
								'id': $scope.Categories.destroy
							})
							.then(rsp => {
								toaster.success('Categpry Deleted!')
								$scope.Company.categories = $scope.Company.categories
									.filter(cat => cat.id !== $scope.Categories.destroy)
								$scope.Categories.create = ''
								$scope.Categories.destroy = false
								$scope.Close()
							})
							.catch(err => {
								toaster.error('Error deleting category')
								$log.error(err)
							})
					} // END ProductDelete()
				}] // END Controller
			})
		}

		$scope.AuctionTimesUpdate = () => {
			const auctionTimes = angular.copy($scope.Company.auction_times)
			auctionTimes.map(day => {
				day.day_start = moment(day.day_start).format('HH:mm:ss')
				day.day_end = moment(day.day_end).format('HH:mm:ss')
				return day
			})
			let AuctionTimesXHR = new SvXHR('auction_times')
			AuctionTimesXHR.raw(auctionTimes, 'PUT')
				.then(rsp => {
					toaster.success('Auction times updated!')
				})
				.catch(err => {
					toaster.error('Error updating auction times')
					$log.error(err)
				})
			return true
		}
	})
