'use strict'

angular.module('App')
	.controller('CtrlTransactionCreate', function (
		$window, $document, $rootScope, $scope, $state, $stateParams, $log,
		$fancyModal, toaster, uiGridConstants,
		SvQuery, SvStatic, SvXHR, TransactionService) {
		// // VARS
		$scope.TransactionCreated = false
		const LotStatusList = SvStatic.lotStatus()
		const CustomerXHR = new SvXHR('customers')
		const LotXHR = new SvXHR('lots')
		const LotListXHR = new SvXHR('lots/list')
		const TransactionXHR = new SvXHR('transactions')

		// // FUNCTIONS

		$scope.CustomerFill = function (args: any = {}) {
			if (!args) args = {}
			if ($scope.Customer.id) args.id = $scope.Customer.id
			if ($scope.Customer.name_first) args.name_first = '*' + $scope.Customer.name_first + '*'
			if ($scope.Customer.name_last) args.name_last = '*' + $scope.Customer.name_last + '*'

			// empty object
			if (_.size(args) === 0) return toaster.info('Please provide an id or name to search')

			$scope.Customer.loading = true
			CustomerXHR.index(args)
				.then(rsp => {
					let dt = rsp.data
					// empty result
					if (dt.length === 0) {
						$scope.Customer.loading = false
						return toaster.info('No matching customers found')
					}
					// get first result
					$scope.Customer = _.cloneDeep(dt[0])

          // recalculate prices, customer may have special commission
          if ($stateParams.lid) {
            const lotIds = $stateParams.lid.split(',') || false
            $scope.LotFill(lotIds)
          } else {
            $scope.LotsCalculate()
          }

					$scope.Customer.loading = false
				})
				.catch(err => {
					CustomerXHR.toastout(err)
					$scope.Customer.loading = false
				})
		} // END CustomerFill()

		$scope.CustomerClear = function () {
			$scope.Customer = $scope.CustomerDefault()
		}

		$scope.LotFill = lotIds => {
			let lotStr = lotIds.map(lid => 'ids[]=' + lid).join('&')
			LotListXHR.raw(lotStr)
				.then(rsp => {
					let dt = rsp.data
					if (dt.length === 0) return toaster.info('No matching lots found')
					// insert lots at top of grid list
					for (let lot of dt) {
						$scope.GridTransactionLots.data.unshift(lot)
						// If transaction is incoming, set status to WON and collected to now
						if ($scope.Transaction.type === 1) {
							lot.status = 2
							lot.collected_at = moment().toISOString()
						}
					}
					$scope.LotsCalculate()
					return false
				})
				.catch(err => LotListXHR.toastout(err))
		} // END LotFill()

		$scope.TransactionCreateLoading = false
		$scope.TransactionCreate = () => {
			// Prevent Duplicate Load
			if ($scope.TransactionCreateLoading) return false
			// Validation
			if ($scope.formTransactionCustomer.$invalid) {
				$scope.TransactionCreateLoading = false
				toaster.warning('Please correct invalid fields')
				return false
			}

			// // PREP PACKET
			let Packet: any = {
				customer: {},
				transaction: {},
				lots: {}
			}
			// CUSTOMER:Add any dirty form fields, add ID if existing customer
			for (let key in $scope.formTransactionCustomer) {
				if (!$scope.formTransactionCustomer.hasOwnProperty(key))
					continue
				if (key[0] !== '$' && $scope.formTransactionCustomer[key].$dirty) {
					Packet.customer[key] = $scope.Customer[key]
				}
				// store address_state for new customer
				if (key === 'address_state' && $scope.Customer.id === null) {
					Packet.customer[key] = $scope.Customer[key]
				}
			}
			Packet.customer.id = $scope.Customer.id
			if (!parseInt(Packet.customer.id, 10) || Packet.customer.id === null) delete Packet.customer.id
			// TRANSACTION: add transaction info
			Packet.transaction = $scope.Transaction
			if (!Packet.transaction.calculation) {
				$scope.TransactionCreateLoading = false
				return toaster.warning('Please wait a moment for portal to load')
			}
			// LOTS: Add valid, non-blank lots to transaction
			Packet.lots = $scope.GridTransactionLots.data
				.reduce((sum, lot) => {
					let tmp = _.cloneDeep(lot)
					delete tmp.lot
					if (tmp.description) {
						if (!tmp.price) tmp.price = 0
						sum.push(tmp)
					}
					return sum
				}, [])

			Packet = SvQuery.clearNullsAuthors(Packet)

			$scope.TransactionCreateLoading = true
			// Create Transaction
			if (!Packet.transaction.id) {
				TransactionXHR.create(Packet)
					.then(rsp => {
						let dt = rsp.data
						toaster.success('Transaction Created!')
						// assign ID
						$scope.Transaction.id = rsp.data.id
						$window.scrollTo(0, 0)
						$scope.TransactionCreated = true // Disable further saving until form reset
						// Print Requested
						if ($scope.Transaction.print) {
							if (dt.print.status === false) toaster.error('Error generating receipt: ', dt.print.message)
							else $window.open(dt.print.message)
						}
						// Email Requested
						if ($scope.Transaction.email) {
							if (dt.email.status === false) toaster.error('Error sending email: ', dt.email.message)
							else toaster.success('Email sent!')
						}
						return false
						// $state.reload();
					})
					.catch(err => {
						TransactionXHR.toastout(err)
						$scope.TransactionCreateLoading = false
					})
			}
		} // END TransactionCreate()

		$scope.BidRemove = function (row) {
			const bidIdx = $scope.GridTransactionLots.data
				.findIndex((el) => (el.$$hashKey === row.entity.$$hashKey))
			if (bidIdx !== -1) {
				// undo any cell edit state
				$document[0].activeElement.blur()
				$scope.GridTransactionLots.data.splice(bidIdx, 1)
			}
		} // END BidRemove()

		$scope.LotsCalculateLoading = false
		let LotsCalculateQueueTicket = 1
		$scope.LotsCalculate = () => {
			// check for valid price for each non-blank lot
			const findInvalid = $scope.GridTransactionLots.data
				.some(lot => lot.id && lot.price === undefined)
			if (findInvalid) return false

			// used for loading indicator
			$scope.LotsCalculateLoading = true

			// QueueTicket: prep for xhr, and increment
			let thisTicket = ++LotsCalculateQueueTicket

			// remove blanks from calculation
			const lots = $scope.GridTransactionLots.data.filter(lot => lot.id)

			// // XHR
			const CalculationPacket = TransactionService
				.calculationPacket($scope.Customer, $scope.Transaction, lots)
			if (!CalculationPacket) {
				$scope.LotsCalculateLoading = false
				return false
			}
			const CalculateXHR = new SvXHR('transactions/calculate')

			CalculateXHR.raw(CalculationPacket, 'POST')
				.then(rsp => {
					// check execution order
					if (thisTicket >= LotsCalculateQueueTicket) {
						// Update Transaction object properties with returned values
						for (let prop in rsp.data) {
							if ($scope.Transaction.hasOwnProperty(prop)) {
								$scope.Transaction[prop] = rsp.data[prop]
							}
						}
						// Calculate display values
						$scope.Transaction.fee = rsp.data.lot_fee + rsp.data.bid_sheet_fee
						$scope.Transaction.fee_gst = rsp.data.lot_fee_gst + rsp.data.bid_sheet_fee_gst
						$scope.Transaction.rounding_difference = rsp.data.rounding_difference
						$scope.Transaction.rounding_difference = rsp.data.rounding_difference
							? rsp.data.rounding_difference
							: 0
						if ($scope.Transaction.rounding_difference === 0)
							$scope.Transaction.rounding_difference = '000' // weird hack for non-rendering of 0 values
						$scope.Transaction.calculation = rsp.data.calculation

						$scope.LotsCalculateLoading = false
					} // END IF Queue Execution
				})
				.catch(err => {
					console.log('lots calculate err', err)
					$scope.LotsCalculateLoading = false
				})
			return false
		} // END LotsCalculate()

		$scope.CollectedToggle = (row) => {
			if (row.entity.collected_at) delete row.entity.collected_at
			else row.entity.collected_at = moment().toISOString()
		}

		// // GRID
		$scope.GridTransactionLots = {
			loading: false,
			enableCellEdit: true,
			showColumnFooter: true,
			columnDefs: [{
					name: 'Lot #',
					field: 'visual_id',
					width: 100,
					enableColumnMenu: false
				},
				{
					name: 'Description',
					field: 'description',
					cellClass: 'text-left pdg-l',
					enableColumnMenu: false
				},
				{
					name: 'Status',
					field: 'status',
					cellFilter: 'filterLotStatus',
					width: 100,
					enableCellEdit: true,
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: LotStatusList,
					enableColumnMenu: false
				},
				{
					name: 'Collected',
					field: 'collected_at',
					width: 100,
					enableColumnMenu: true,
					enableHiding: false,
					menuItems: [{
							title: 'Select All',
							icon: 'ui-grid-icon-info-circled',
							action: function ($event) {
								$scope.GridTransactionLots.data.filter((lot) => {
									if (lot.id) lot.collected_at = moment().toISOString()
									return lot
								})
							},
							context: $scope
						},
						{
							title: 'Deselect All',
							icon: 'ui-grid-icon-cancel',
							action: function ($event) {
								$scope.GridTransactionLots.data.filter((lot) => delete lot.collected_at)
							},
							context: $scope
						}
					],
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'" ng-click="grid.appScope.CollectedToggle(row)"></div>`// tslint:disable
				},
				{
					name: 'Sale Price',
					field: 'price',
					cellFilter: 'dosh',
					width: 100,
					enableCellEdit: true,
					enableColumnMenu: false,
					cellTemplate: '<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | dosh"></div>',
					editableCellTemplate: '<input ng-class="' + "'" + 'colt' + "'" + ' + col.uid" ng-model="row.entity[col.field]" ui-grid-editor type="text" ng-value="row.entity[col.field] ? row.entity[col.field]/100 : 0" ng-change="grid.appScope.LotsCalculate()" smart-float>',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: '<div class="ui-grid-cell-contents"><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>'
				},
				{
					name: '',
					field: 'delete',
					enableColumnMenu: false,
					width: 40,
					enableCellEdit: false,
					cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.BidRemove(row)"><i class="fa fa-times grapefruit-dark"></i></div>'
				}
			]
		}
		$scope.GridTransactionLots.onRegisterApi = function (gridApi) {
			$scope.gridTransactionLotsApi = gridApi
			// EVENT LISTENERS
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				$scope.$apply()
				// Value Changed:
				if (newVal !== oldVal) {
					// Lot Visual ID validation
					if (colDef.field === 'visual_id' || colDef.field === 'description') {
						$scope.SaveDisabled = true
						// Find matching lot
						let args = {
							'order_by': 'id,desc',
							'limit': 15
						}
						// if field is nested, extract nested name
						let field = (colDef.field.includes('.')) ? colDef.field.split('.')[1] : colDef.field
						// 'searchable' flag for description
						if (field === 'description') {
							args['search_fields'] = field
							args['search_terms'] = '*' + newVal + '*'
						} else
							// explicit match for visual_id
							if (field === 'visual_id') args[field] = newVal

						// search lot by entered description/visual_id, and fill in corresponding row values
						LotXHR.index(args)
							.then(rsp => {
								let dt = rsp.data
								// 0 matches
								if (dt.length === 0) return toaster.warning('No lots found for lot number ' + newVal)
								// 1+ matches
								let bidIdx = $scope.GridTransactionLots.data.findIndex((el) => (el.$$hashKey === rowEntity.$$hashKey))
								$scope.GridTransactionLots.data[bidIdx].id = dt[0].id
								$scope.GridTransactionLots.data[bidIdx].visual_id = dt[0].visual_id
								$scope.GridTransactionLots.data[bidIdx].description = dt[0].description
								$scope.GridTransactionLots.data[bidIdx].status = dt[0].status
								$scope.GridTransactionLots.data[bidIdx].price = dt[0].price
								// If transaction type = incoming, set status to WON automatically and collected to now
								if ($scope.Transaction.type === 1) {
									$scope.GridTransactionLots.data[bidIdx].status = 2
									$scope.GridTransactionLots.data[bidIdx].collected_at = moment().toISOString()
								}
								$scope.SaveDisabled = false
								// Calculate Lots
								$scope.LotsCalculate()
							})
							.catch(err => LotXHR.toastout(err))
					} // END lot visual_id/description validation
				} // END newVal != oldVal
			}) // END afterCellEdit
		}

		// // INITIALISATION
		// set up grid default with blank rows
		const Bid = {
			created_at: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const BidDef = []
		for (let i = 0; i < 50; i++) BidDef.push(_.cloneDeep(Bid))
		$scope.GridTransactionLots.data = _.cloneDeep(BidDef)

		// // STATE
		if ($stateParams.cid) $scope.CustomerFill({
			'id': $stateParams.cid
		})
		if ($stateParams.type) {
			$scope.Transaction.type = parseInt($stateParams.type)
		}
	}) // END CONTROLLER
