'use strict'

// Static variable lists
angular.module('App').factory('SvStatic', function (HTP, API_URL, SvQuery) {
	return {
	states: () => {
		return [
				{ id: 1, name: 'ACT' },
				{ id: 2, name: 'NSW' },
				{ id: 3, name: 'NT' },
				{ id: 4, name: 'SA' },
				{ id: 5, name: 'TAS' },
				{ id: 6, name: 'VIC' },
				{ id: 7, name: 'WA' },
			{ id: 8, name: 'QLD' }
		]
	},
	contactTypes: () => {
		return [
			{ id: 1, name: 'Email' },
			{ id: 2, name: 'Call Mobile' },
			{ id: 3, name: 'Call Home' },
			{ id: 4, name: 'Call Work' },
			{ id: 5, name: 'SMS' }
		]
	},
	bidStatus: () => {
		return [
			{ id: 1, name: 'Open', description: 'Not yet processed' },
			{ id: 2, name: 'Won', description: 'Won' },
			{ id: 3, name: 'Lost', description: 'Lost' },
			{ id: 4, name: 'Underbid', description: 'Below reserve, pending review' },
			{ id: 5, name: 'Refunded', description: 'Bid won, paid, bidder refunded' },
			{ id: 6, name: 'Cancelled', description: 'Cancelled' }
		]
	},
	lotStatus: () => {
		return [
			{ id: 1, name: 'Open', description: 'Open for bidding, no bids made' },
			{ id: 2, name: 'Sold', description: 'Sold' },
			{ id: 3, name: 'Underbid', description: 'Under reserve bids exist' },
			{ id: 4, name: 'Expired', description: 'Lot exceeded sale rounds, reserve dropped to 0' },
			{ id: 5, name: 'Returned', description: 'Lot returned to seller' },
			{ id: 6, name: 'Disposed', description: 'Lot discarded' },
			{ id: 7, name: 'Closed', description: 'Lot closed, miscellaneous reasons' },
			{ id: 8, name: 'No Bids', description: 'No bids on lot' }
		]
	},
	transactionType: () => {
		return [
			{ id: 1, name: 'Incoming Payment', short: 'Incoming', description: 'Bidder is paying for lot' },
			{ id: 2, name: 'Outgoing Payment', short: 'Outoing', description: 'Seller is receiving payment for lot' }, // tslint:disable-line
			{ id: 3, name: 'Refund', short: 'Refund', description: 'Customer refund' },
			{ id: 4, name: 'Return', short: 'Return', description: 'Returning unsold goods' }
		]
	}
	}
})
