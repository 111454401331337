'use strict'

angular.module('App')
	.directive('gridRow', function ($log) {
		return {
			templateUrl: 'html/common/grid/grid-row.html',
			restrict: 'E',
			replace: true,
			scope: {
				onRowSelect: '=?'
			},
			link: function (scope, el, attrs) {
				scope.onRowSelect = (scope.onRowSelect) ? scope.onRowSelect : 'RowSelected'
			}
		}
	})
	.directive('gridLoader', function ($log) {
		return {
			templateUrl: 'html/common/grid/grid-loader.html',
			restrict: 'E',
			replace: true,
			scope: {
				loadModel: '=',
				gridOverlay: '=?'
			},
			link: function (scope, el, attrs) {
				// Vars
				scope.loading = false
				scope.gridOverlay = scope.gridOverlay || false

				// Loading Watcher
				scope.$watch('loadModel', function (newVal) {
					scope.loading = newVal || false
				})

				// Styling
				scope.loadingOverlayStyling = {
					'position': 'absolute',
					'top': '0',
					'bottom': '0',
					'width': '100%',
					'background': 'rgba(0, 0, 0, 0.4)'
				}
				scope.msgStyling = {
					'opacity': '1',
					'position': 'absolute',
					'top': '40%',
					'left': '20%',
					'width': '60%',
					'height': '50%',
					'text-align': 'center',
					'font-size': '24px',
					'display': 'table'
				}
				scope.msgInnerStyling = {
					'display': 'table-cell',
					'vertical-align': 'middle'
				}

				// pverlay disabled
				if (!scope.gridOverlay) scope.loadingOverlayStyling = {}
			}
		}
	})
	.directive('gridEmpty', function ($log) {
		return {
			templateUrl: 'html/common/grid/grid-empty.html',
			restrict: 'E',
			replace: true,
			scope: {
				loadModel: '=',
				emptyModel: '=',
				message: '=?'
			},
			link: function (scope, el, attrs) {
				// Vars
				scope.msg = (scope.message) ? scope.message : 'No results'

				// Empty Watcher
				scope.isEmpty = true
				scope.$watch('loadModel', function (newVal) {
					console.log('gridEmpty', newVal)
					if (scope.emptyMpdel || newVal) scope.isEmpty = false
					else scope.isEmpty = true
				})

				// Styling
				scope.emptyOverlayStyling = {
					'position': 'absolute',
					'top': '60px',
					'bottom': '0',
					'width': '100%'
				}
				scope.msgStyling = {
					'opacity': '1',
					'position': 'absolute',
					'top': '20%',
					'left': '20%',
					'width': '60%',
					'height': '50%',
					'text-align': 'center',
					'font-size': '24px',
					'display': 'table'
				}
				scope.msgInnerStyling = {
					'display': 'table-cell',
					'vertical-align': 'middle'
				}
			}
		}
	})
