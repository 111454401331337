"use strict";
angular.module('App')
    .controller('CtrlLotIndex', function ($rootScope, $scope, $state, $stateParams, $log, toaster, SvXHR) {
    // // FUNCTIONS
    $scope.TransactionIndexCreate = (type) => {
        // Prep lot IDs array
        let lotIds = $scope.gridLotIndexApi.selection.getSelectedRows()
            // check lot status is open (1) or won (2)
            // .filter( lot => (lot.status == 1 || lot.status == 2) )
            // return lot id only
            .map(lot => lot.id);
        if (lotIds.length === 0)
            return toaster.info('No valid lots selected for transaction');
        // go to transactions create view
        $state.go('company.transactions.create', {
            'cid': $scope.Customer.id,
            'lid': lotIds.join(','),
            'type': type
        });
        return false;
    };
    // // STATE
    if ($stateParams.cid) {
        $scope.Customer.id = $stateParams.cid;
        $scope.LotsIndex();
    }
}); // END CONTROLLER
