'use strict';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
angular.module('App')
    .controller('CtrlCash', function ($rootScope, $scope, $log, $fancyModal, toaster, uiGridConstants, SvStatic, SvQuery, SvXHR) {
    // // VARS
    const TransactionXHR = new SvXHR('transactions');
    $scope.Inputs = {
        created_from: moment(),
        created_to: moment().add(1, 'days'),
        cob: 0,
        d_lost_stock_adjustments: 0,
    };
    const CashValuesInitial = {
        a_receipts: 0,
        b_payments: 0,
        c_refunds: 0,
        d_lost_stock_adjustments: 0,
        e_subtotal: 0,
        f_total: 0,
    };
    $scope.CashValues = CashValuesInitial;
    const EftValuesInitial = {
        total: 0,
        refunds: 0,
        incoming: 0,
        outgoing: 0,
    };
    $scope.EftValues = EftValuesInitial;
    // // FUNCTIONS
    $scope.UpdateInputs = function () {
        return __awaiter(this, void 0, void 0, function* () {
            const cob = $scope.Inputs.cob;
            const queryPacket = {
                'limit': 'unlimited',
                'created_at>': moment($scope.Inputs.created_from).toISOString(),
                'created_at<': moment($scope.Inputs.created_to).toISOString()
            };
            // XHR
            try {
                const rsp = yield TransactionXHR.index(queryPacket);
                const data = rsp.data;
                // Cash
                const cashTxns = data.filter(t => t.payment_method === TxnPaymentMethodEnum.CASH);
                const a_receipts = cashTxns
                    .filter(t => t.type === PaymentTypeEnum.INCOMING_PAYMENT)
                    .map(t => t.total || 0)
                    .reduce((sum, t) => sum + t, 0);
                const b_payments = cashTxns
                    .filter(t => t.type === PaymentTypeEnum.OUTGOING_PAYMENT)
                    .map(t => t.total || 0)
                    .reduce((sum, t) => sum + t, 0);
                const c_refunds = cashTxns
                    .filter(t => t.type === PaymentTypeEnum.REFUND)
                    .map(t => t.total || 0)
                    .reduce((sum, t) => sum + t, 0);
                const d_lost_stock_adjustments = $scope.Inputs.d_lost_stock_adjustments;
                const e_subtotal = b_payments + c_refunds + d_lost_stock_adjustments;
                const f_total = cob + a_receipts - e_subtotal;
                const CashValues = {
                    a_receipts,
                    b_payments,
                    c_refunds,
                    d_lost_stock_adjustments,
                    e_subtotal,
                    f_total,
                };
                $scope.CashValues = CashValues;
                // Eftpos
                const eftTxns = data.filter(t => t.payment_method === TxnPaymentMethodEnum.EFTPOS);
                const eftIncoming = eftTxns
                    .filter(t => t.type === PaymentTypeEnum.INCOMING_PAYMENT)
                    .map(t => t.total || 0)
                    .reduce((sum, t) => sum + t, 0);
                const eftOutgoing = eftTxns
                    .filter(t => t.type === PaymentTypeEnum.OUTGOING_PAYMENT)
                    .map(t => t.total || 0)
                    .reduce((sum, t) => sum + t, 0);
                const eftRefunds = eftTxns
                    .filter(t => t.type === PaymentTypeEnum.REFUND)
                    .map(t => t.total || 0)
                    .reduce((sum, t) => sum + t, 0);
                const eftTotal = eftIncoming - eftOutgoing - eftRefunds;
                const EftValues = {
                    total: eftTotal,
                    refunds: eftRefunds,
                    incoming: eftIncoming,
                    outgoing: eftOutgoing,
                };
                $scope.EftValues = EftValues;
            }
            catch (err) {
                return TransactionXHR.toastout(err);
            }
        });
    };
    $scope.printPage = function () {
        window.print();
    };
    // INITIALISE
    $scope.UpdateInputs();
}); // END CONTROLLER
var TxnPaymentMethodEnum;
(function (TxnPaymentMethodEnum) {
    TxnPaymentMethodEnum[TxnPaymentMethodEnum["CASH"] = 1] = "CASH";
    TxnPaymentMethodEnum[TxnPaymentMethodEnum["EFTPOS"] = 2] = "EFTPOS";
    TxnPaymentMethodEnum[TxnPaymentMethodEnum["FUNDS_TRANSFER"] = 3] = "FUNDS_TRANSFER";
})(TxnPaymentMethodEnum || (TxnPaymentMethodEnum = {}));
var PaymentTypeEnum;
(function (PaymentTypeEnum) {
    PaymentTypeEnum[PaymentTypeEnum["INCOMING_PAYMENT"] = 1] = "INCOMING_PAYMENT";
    PaymentTypeEnum[PaymentTypeEnum["OUTGOING_PAYMENT"] = 2] = "OUTGOING_PAYMENT";
    PaymentTypeEnum[PaymentTypeEnum["REFUND"] = 3] = "REFUND";
    PaymentTypeEnum[PaymentTypeEnum["RETURN"] = 4] = "RETURN";
})(PaymentTypeEnum || (PaymentTypeEnum = {}));
