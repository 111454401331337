"use strict";
angular.module('App')
    .controller('CtrlBidUpdate', function ($window, $document, $rootScope, $scope, $state, $stateParams, $log, $fancyModal, toaster, uiGridConstants, SvStatic, SvQuery, SvXHR) {
    // // VARS
    $scope.SaveDisabled = false;
    const BidStatusList = SvStatic.bidStatus();
    const BidSheetXHR = new SvXHR('bidsheets');
    const BidXHR = new SvXHR('bids');
    const CustomerXHR = new SvXHR('customers');
    const LotXHR = new SvXHR('lots');
    // set up grid default with blank rows
    const Bid = {
        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    const BidDef = [];
    // Datepicker
    $scope.BidSheetSignedPopup = {
        format: 'dd MMMM yyyy',
        manualInput: ['dd-MMMM-yyyy', 'dd/MM/yyyy', 'dd.MM.yyyy', 'shortDate'],
        opened: false,
        options: {
            formatYear: 'yy',
            minDate: new Date(1990, 1, 1),
            maxDate: new Date(2020, 1, 1),
            startingDay: 1
        },
        open: () => {
            $scope.BidSheetSignedPopup.opened = true;
        }
    };
    // // FUNCTIONS
    $scope.BidSheetShow = function (id, args = {}) {
        // enables loading state in header
        delete $scope.BidSheet.id;
        args = {
            'id': id,
            'includes': 'bids.lot,customer'
        };
        $scope.LoadingModal();
        BidSheetXHR.index(args)
            .then(rsp => {
            const tmpBidSheet = rsp.data[0];
            try {
                // Parse Dates
                let signedDate = moment(tmpBidSheet.signed);
                tmpBidSheet.signed = signedDate.isValid() ? signedDate.toDate() : moment().toDate();
            }
            catch (err) {
                $log.error('BidSheet dates parse error', err);
            }
            $scope.BidSheet = tmpBidSheet;
            $log.debug($scope.BidSheet);
            // insert blank rows
            $scope.GridBidSheetShow.data = _.concat($scope.BidSheet.bids, _.cloneDeep(BidDef));
            $scope.Customer = $scope.BidSheet.customer;
            $log.debug('BidSheet', $scope.BidSheet);
            $fancyModal.close();
        })
            .catch(err => {
            BidSheetXHR.toastout(err);
            $fancyModal.close();
        });
    }; // END BidSheetShow()
    $scope.TransactionCreate = () => {
        // Prep lot IDs array
        let lotIds = $scope.gridBidSheetShowApi.selection.getSelectedRows()
            // check bid has loaded lot
            .filter(bid => (bid.lot && bid.lot.id))
            // return lot id only
            .map(bid => bid.lot.id);
        if (lotIds.length === 0)
            return toaster.info('No valid lots selected for transaction');
        // go to transactions create view
        let stateVars = {};
        stateVars.lid = lotIds.join(',');
        stateVars.type = 1;
        if ($scope.Customer.id)
            stateVars.cid = $scope.Customer.id;
        $state.go('company.transactions.create', stateVars);
        return false;
    };
    $scope.CustomerFill = function (args = {}) {
        if (!args)
            args = {};
        if ($scope.Customer.id)
            args.id = $scope.Customer.id;
        if ($scope.Customer.name_first)
            args.name_first = '*' + $scope.Customer.name_first + '*';
        if ($scope.Customer.name_last)
            args.name_last = '*' + $scope.Customer.name_last + '*';
        // empty object
        if (_.size(args) === 0)
            return toaster.info('Please provide an id or name to search');
        $scope.Customer.loading = true;
        CustomerXHR.index(args)
            .then(rsp => {
            let dt = rsp.data;
            // empty result
            if (dt.length === 0) {
                $scope.Customer.loading = false;
                return toaster.info('No matching customers found');
            }
            // get first result
            $scope.Customer = _.cloneDeep(dt[0]);
            if ($scope.Customer.credit_rating === 0)
                toaster.warning('CAUTION: Customer has BAD CREDIT');
            $log.debug('CustomerFill', dt[0]);
            $scope.Customer.loading = false;
        })
            .catch(err => {
            CustomerXHR.toastout(err);
            $scope.Customer.loading = false;
        });
    }; // END CustomerFill()
    $scope.CustomerClear = function () {
        $scope.Customer = $scope.CustomerDefault();
    };
    $scope.BidSheetCreateUpdateLoading = false;
    $scope.BidSheetCreateUpdate = function () {
        $log.debug('UpdateBidSheet', $scope.formBidSheetCustomer);
        // Prevent Duplicate Load
        if ($scope.BidSheetCreateUpdateLoading)
            return false;
        // Validation
        if ($scope.formBidSheetCustomer.$invalid) {
            $scope.BidSheetCreateUpdateLoading = false;
            return toaster.warning('Please correct invalid fields');
        }
        // PREP UPDATE PACKET
        let Packet = {
            customer: {},
            bid_sheet: {}
        };
        // CUSTOMER:Add any dirty form fields, add ID if existing customer
        for (let key in $scope.formBidSheetCustomer) {
            if (!$scope.formBidSheetCustomer.hasOwnProperty(key))
                continue;
            if (key[0] !== '$' && $scope.formBidSheetCustomer[key].$dirty) {
                Packet.customer[key] = $scope.Customer[key];
            }
            // store address_state for new customer
            if (key === 'address_state' && $scope.Customer.id === null) {
                Packet.customer[key] = $scope.Customer[key];
            }
        }
        Packet.customer.id = $scope.Customer.id;
        if (!parseInt(Packet.customer.id, 10) || Packet.customer.id === null)
            delete Packet.customer.id;
        // BIDSHEET: Add visual_id and created_at from BidSheet obj
        Packet.bid_sheet = _.cloneDeep($scope.BidSheet);
        delete Packet.bid_sheet.customer;
        // BID: Add valid, non-blank bids to bidsheet
        Packet.bid_sheet.bids = $scope.GridBidSheetShow.data.reduce((sum, bid) => {
            let tmp = _.cloneDeep(bid);
            delete tmp.lot;
            if (parseInt(tmp.bid_value, 10) && parseInt(tmp.bid_value, 10) !== 0)
                sum.push(tmp);
            return sum;
        }, []);
        Packet = SvQuery.clearNullsAuthors(Packet);
        $scope.BidSheetCreateUpdateLoading = true;
        // New BidSheet
        if (!Packet.bid_sheet.id) {
            BidSheetXHR.create(Packet)
                .then(rsp => {
                toaster.success('BidSheet Created!');
                // assign ID
                $scope.BidSheet.id = rsp.data.id;
                $scope.BidSheet.visual_id = rsp.data.visual_id;
                $scope.BidSheetCreateUpdateLoading = false;
                // reload form to bids/update with new id
                $state.transitionTo('company.bids.update', {
                    sheet_id: rsp.data.id
                }, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
            })
                .catch(err => {
                BidSheetXHR.toastout(err);
                $scope.BidSheetCreateUpdateLoading = false;
            });
            // Existing BidSheet
        }
        else {
            BidSheetXHR.update(Packet, Packet.bid_sheet.id)
                .then(rsp => {
                toaster.success('BidSheet Updated!');
                $scope.formBidSheetCustomer.$setPristine();
                $scope.BidSheetCreateUpdateLoading = false;
                $window.scrollTo(0, 0);
                // $state.reload(); // DO NOT RELOAD VIEW
            }).catch(err => {
                BidSheetXHR.toastout(err);
                $scope.BidSheetCreateUpdateLoading = false;
            });
        }
    }; // END BidSheetCreateUpdate()
    $scope.BidRemove = function (row) {
        $log.debug('BidRemove', row);
        const bidIdx = $scope.GridBidSheetShow.data
            .findIndex((el) => (el.$$hashKey === row.entity.$$hashKey));
        if (bidIdx !== -1) {
            // undo any cell edit state
            $document[0].activeElement.blur();
            $scope.GridBidSheetShow.data.splice(bidIdx, 1);
        }
        // delete from db if row is pre-existing
        if (row.entity.id) {
            BidXHR.destroy(row.entity)
                .then(rsp => toaster.success('Bid deleted!'))
                .catch(err => BidXHR.toastout(err));
        }
    }; // END BidRemove()
    // // GRID
    $scope.GridBidSheetShow = {
        loading: false,
        enableColumnMenus: false,
        enableCellEdit: true,
        showColumnFooter: true,
        enableRowSelection: true,
        multiSelect: true,
        enableSelectAll: false,
        columnDefs: [{
                name: 'Lot #',
                field: 'lot.visual_id',
                width: 100
            },
            {
                name: 'Description',
                field: 'lot.description',
                cellClass: 'text-left pdg-l'
            },
            {
                name: 'Area',
                field: 'lot.area',
                width: 100,
                enableCellEdit: false
            },
            {
                name: 'Bid Price',
                field: 'bid_value',
                cellFilter: 'dosh',
                width: 100,
                cellTemplate: `<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | dosh"></div>`,
                editableCellTemplate: '<input ng-class="' + "'" + 'colt' + "'" + ' + col.uid" ng-model="row.entity[col.field]" ui-grid-editor type="text" ng-value="row.entity[col.field] ? row.entity[col.field]/100 : 0" smart-float>',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                footerCellTemplate: '<div class="ui-grid-cell-contents" ><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>' //tslint:disable-line
            },
            {
                name: 'Bid Status',
                field: 'status',
                cellFilter: 'filterBidStatus',
                width: 100,
                enableCellEdit: true,
                editableCellTemplate: 'ui-grid/dropdownEditor',
                editDropdownValueLabel: 'name',
                editDropdownOptionsArray: BidStatusList
            },
            {
                name: 'Reserve',
                field: 'lot.reserve_bid',
                width: 100,
                cellFilter: 'dosh:\'-\'',
                cellClass: 'disabled',
                enableCellEdit: false
            },
            {
                name: 'Sold For',
                field: 'lot.price',
                width: 100,
                cellFilter: 'dosh:\'-\'',
                cellClass: 'disabled',
                enableCellEdit: false
            },
            {
                name: '',
                field: 'delete',
                enableColumnMenu: false,
                width: 40,
                enableCellEdit: false,
                cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.BidRemove(row)"><i class="fa fa-times grapefruit-dark"></i></div>' //tslint:disable-line
            }
        ]
    };
    $scope.GridBidSheetShow.onRegisterApi = function (gridApi) {
        $scope.gridBidSheetShowApi = gridApi;
        // EVENT LISTENERS
        gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
            $log.debug('rowEntity:', rowEntity);
            $log.debug('colDef:', colDef, ' newVal:', newVal, ' oldVal:', oldVal);
            $scope.$apply();
            // Value Changed:
            if (newVal !== oldVal) {
                // Lot Visual ID validation
                if (colDef.field === 'lot.visual_id' || colDef.field === 'lot.description') {
                    $scope.SaveDisabled = true;
                    // Find matching lot
                    let args = {
                        'order_by': 'id,desc'
                    };
                    let field = colDef.field.split('.')[1];
                    // 'searchable' flag for description
                    if (field === 'description') {
                        args['search_fields'] = field;
                        args['search_terms'] = '*' + newVal + '*';
                        // explicit match for visual_id
                    }
                    else if (field === 'visual_id')
                        args[field] = newVal;
                    // search lots
                    LotXHR.index(args)
                        .then(rsp => {
                        let dt = rsp.data;
                        // // 0 matches
                        if (dt.length === 0)
                            return toaster.warning('No lots found for lot number ' + newVal);
                        // // 1+ matches
                        // Validate: must be one of: open, underbid, expired, no-bids
                        if (dt[0].status !== 1 && dt[0].status !== 3 && dt[0].status !== 4 && dt[0].status !== 8) {
                            toaster.pop({
                                'type': 'warning',
                                'title': 'CAUTION',
                                'body': 'Lot is not open for bidding, are you sure you want to do this?',
                                'timeout': 20000,
                                'showCloseButton': true
                            });
                        }
                        // Add to grid
                        let bidIdx = $scope.GridBidSheetShow.data
                            .findIndex((el) => (el.$$hashKey === rowEntity.$$hashKey));
                        $scope.GridBidSheetShow.data[bidIdx].lot_id = dt[0].id;
                        $scope.GridBidSheetShow.data[bidIdx].lot.area = dt[0].area;
                        $scope.GridBidSheetShow.data[bidIdx].lot.visual_id = dt[0].visual_id;
                        $scope.GridBidSheetShow.data[bidIdx].lot.description = dt[0].description;
                        $scope.GridBidSheetShow.data[bidIdx].lot.status = dt[0].status;
                        $scope.GridBidSheetShow.data[bidIdx].lot.price = dt[0].price;
                        // If new bid, no status
                        if (!$scope.GridBidSheetShow.data[bidIdx].status)
                            $scope.GridBidSheetShow.data[bidIdx].status = 1;
                        $scope.SaveDisabled = false;
                        $log.debug('lot search result', rsp.data);
                    })
                        .catch(err => LotXHR.toastout(err));
                }
            }
        });
    };
    // // INITIALISATION
    for (let i = 0; i < 100; i++)
        BidDef.push(_.cloneDeep(Bid));
    $scope.GridBidSheetShow.data = _.cloneDeep(BidDef);
    // // STATE
    if ($stateParams.sheet_id)
        $scope.BidSheetShow($stateParams.sheet_id);
    // New BidSheet
    else
        $scope.BidSheet.id = null;
}); // END CONTROLLER
