'use strict'

angular.module('App')
	.controller('CtrlSoldInstore', function ($rootScope, $scope, $log, $fancyModal, toaster,
		uiGridConstants, SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.reportLoaded = false
		$scope.reportData = ''
		const ReportingXHR = new SvXHR('reporting/sold_instore')

		// // FUNCTIONS
		$scope.loadReport = function () {
			ReportingXHR.index()
				.then(rsp => {
					$scope.reportData = rsp.data
					$scope.reportLoaded = true
					let iframe: any = document.createElement('iframe')
					iframe.srcdoc = rsp.data
					iframe.src = 'data:text/html;charset=utf-8,' + escape(rsp.data)
					iframe.setAttribute('id', 'reportFrame')
					iframe.setAttribute('width', '100%')
					iframe.setAttribute('height', '500px')
					const iFrameWrapper = document.getElementById('iFrameWrapper')
					while (iFrameWrapper.firstChild) iFrameWrapper.removeChild(iFrameWrapper.firstChild)
					iFrameWrapper.appendChild(iframe)
				})
				.catch(err => ReportingXHR.toastout(err))
		}

		$scope.printReport = function () {
			const reportFrame: any = document.getElementById('reportFrame')
			reportFrame.contentWindow.print()
		}

		// INITIALISE
	}) // END CONTROLLER
