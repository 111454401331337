angular.module('App')
	.controller('CtrlCompanyApp', function ($window, $rootScope, $scope, $log, $fancyModal, toaster,
		SvStatic, SvXHR) {
		// // VARS
		$scope.States = SvStatic.states()
		$scope.ContactTypes = SvStatic.contactTypes()
		$scope.User = $rootScope.User
		$scope.Version = $rootScope.Version || ''
		$scope.CustomerDefault = () => {
			return {
				id: null,
				created_at: new Date(),
				dob: moment('1980-01-01').toDate(),
				address_state: $scope.User.company.address_state,
				credit_rating: 1
			}
		}

		// // Window Resolution Adjustments
		const windowCalc = () => {
			let topOffset = 65
			// Loads the correct sidebar on window load,
			let width = ($window.window.innerWidth > 0)
				? $window.window.innerWidth : $window.window.screen.width
			if (width < 768) {
				$('div.navbar-collapse').addClass('collapse')
				topOffset = 100 // 2-row-menu
			} else {
				$('div.navbar-collapse').removeClass('collapse')
			}
			// Sets the min-height of #page-wrapper to window size
			let height = (($window.window.innerHeight > 0)
				? $window.window.innerHeight : $window.window.screen.height) - 1
			height = height - topOffset
			if (height < 1) height = 1
			if (height > topOffset) {
				$('#page-wrapper').css('min-height', (height) + 'px')
			}
		}
		windowCalc()

		$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
			windowCalc()
		}) // END stateChangeSuccess
	})
