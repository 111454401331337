'use strict'

angular.module('App')
	.directive('sidebar', ['$location', 'API_URL', '$state', function ($location, API_URL, $state) {
		return {
			templateUrl: 'html/common/sidebar/sidebar.html',
			restrict: 'E',
			replace: true,
			scope: {},
			controller: function ($scope) {
				$('#side-menu').metisMenu()
				$scope.selectedMenu = 'dashboard'
				$scope.collapseVar = 0
				$scope.multiCollapseVar = 0
				$scope.ResetURL = API_URL + 'reset'

				$scope.check = function (x) {
					if (x === $scope.collapseVar) $scope.collapseVar = 0
					else $scope.collapseVar = x
				}

				$scope.multiCheck = function (y) {
					if (y === $scope.multiCollapseVar) $scope.multiCollapseVar = 0
					else $scope.multiCollapseVar = y
				}

				$scope.ResetDB = () => $state.go('login', {
					m: 'logout'
				})
			}
		}
	}])
