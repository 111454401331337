'use strict'

angular.module('App')
	.controller('CtrlBidIndex', function ($rootScope, $scope, $state, $stateParams, $log, toaster,
		SvXHR) {
		// // FUNCTIONS
		$scope.TransactionIndexCreate = () => {
			// Prep bid IDs array
			let bidIds = $scope.gridBidIndexApi.selection.getSelectedRows()
				// check bid status is open (1) or won (2)
				// .filter( bid => (bid.status == 1 || bid.status == 2) )
				// return bid id only
				.map(bid => bid.lot.id)
			if (bidIds.length === 0) return toaster.info('No valid bids selected for transaction')

			// go to transactions create view
			$state.go('company.transactions.create', {
				'cid': $scope.Customer.id,
				'lid': bidIds.join(','),
				'type': 1 // incoming payment (bidder)
			})

			return false
		}

		$scope.BidRaiseToReserve = () => {
			let bids = $scope.gridBidIndexApi.selection.getSelectedRows()
			// Validate
			if (bids.length === 0) return toaster.info('No bids selected')
			let fail = false
			for (let bid of bids) {
				if (bid.status !== 4) {
					toaster.error('Invalid bid selected')
					fail = true
				}
			}
			if (fail) return false

			// XHR
			let bidIds = bids.map(bid => bid.id)
			let BidRaiseXHR = new SvXHR('bids/raise')
			BidRaiseXHR.raw(bidIds, 'POST')
				.then(rsp => {
					toaster.success('Bids raised!')
					$state.reload()
				})
				.catch(err => BidRaiseXHR.toastout(err))
			return false
		}

		// // STATE
		if ($stateParams.cid) {
			$scope.Customer.id = $stateParams.cid
			$scope.BidsIndex()
		}
	}) // END CONTROLLER
