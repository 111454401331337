"use strict";
angular.module('App')
    .controller('CtrlLogin', function (API_URL, $http, $rootScope, $scope, $log, $auth, $state, $stateParams, $localStorage, $timeout, toaster) {
    // Vars
    $scope.crd = {
        type: 1,
        email: '',
        password: '',
        cid: '',
        surname: ''
    };
    // Retrieve login default if exists
    if ($localStorage.type)
        $scope.crd.type = $localStorage.type;
    // State Parameters
    if ($stateParams.m) {
        if ($stateParams.m === 'logout') {
            $auth.logout();
            $rootScope.User = $scope.User = false;
            toaster.info('Logged out successfully');
        }
    }
    // if already logged, skip to appropriate area
    if ($auth.isAuthenticated() && $localStorage.type) {
        switch ($localStorage.type) {
            case 1:
                $state.go('company.dashboard');
                break;
            case 2:
                $state.go('company.dashboard');
                break;
            default:
                break;
        }
    }
    // Login
    $scope.Loading = false;
    $scope.LoginSubmit = type => {
        // check for dup req
        if ($scope.Loading === true)
            return false;
        $scope.Loading = true;
        $auth.login($scope.crd)
            .then(rsp => {
            $rootScope.User = $scope.User = $auth.getPayload().user;
            $rootScope.Version = $scope.Version = $auth.getPayload().version;
            $localStorage.type = $scope.crd.type;
            // animate
            $('.login-panel').addClass('bounceOutUp');
            $scope.Loading = false;
            // determine destination based on user type
            switch ($scope.User.role_id) {
                case 1:
                case 2:
                    {
                        $state.go('company.dashboard');
                        break;
                    }
                default:
                    break;
            }
        })
            .catch(err => {
            $log.debug('err', err);
            $rootScope.User = $scope.User = false;
            if (err.status === 401) {
                toaster.error('Incorrect Email/Password');
                $('.login-panel').removeClass('bounceInDown');
                $('.login-panel').addClass('shake');
            }
            else {
                toaster.error('Error processing request');
                $('.login-panel').removeClass('bounceInDown');
                $('.login-panel').addClass('shake');
            }
            $scope.Loading = false;
        }); // END XHR
    }; // END LoginSubmit()
});
