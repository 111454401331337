'use strict'

angular.module('App')
	// applies styling and label to checkbox + label element
	// NOTE: requires checkbox.scss!
	.directive('checkBox', function () {
		return {
			restrict: 'E',
			require: 'ngModel',
			scope: {
				label: '=',
				half: '=?' // optional (default is 5)
			},
			template: `
<div class="fancy-checkbox" ng-class="{ 'half' : half }">
<label>{{label}}</label>
<input type="checkbox" ng-model="model"/><span></span>
</div>
`,
			link: function (scope, el, attrs, ctrl) {
				scope.model = true
				// model => directive
				ctrl.$render = () => scope.model = ctrl.$viewValue
				// directive => model
				scope.$watch('model', function (newVal) {
					ctrl.$setViewValue(newVal)
				})
			}
		}
	})
/**
 * SAMPLE USE
 * <div class="flex-container-refund-progress" style="justify-content: space-around;">
 * <div class="flex">
 * <check-box
 * ng-model="CurrentRefund.refund.payment_details_history_id"
 * label="'Payment Info Received?'" half="true">
 * </check-box>
 * </div>
 */

