'use strict'

angular.module('App')
	.filter('to_trusted', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text)
		}
	}])
	.filter('tsToISO', function () {
		// converts SQL timestamp to ISO date object in local timezone of user
		return function (input) {
			if (!input || input === '' || input === undefined) return input
			input = moment(input).toISOString()
			return input
		}
	})
	.filter('dosh', function ($log) {
		return function (amount = 0, empty = '$0.00', symb = '$') {
			if (typeof amount !== 'number' && amount)
				amount = parseInt(String(amount).toString().replace(/^0+/, ''), 10)
			if (isNaN(amount) || amount === null) return empty
			return symb + (amount / 100).toFixed(2)
		}
	})
	.filter('percentage', function ($log) {
		return function (amount, symb) {
			if (!symb) symb = '%' // ascii &#163;
			if (!amount || isNaN(amount)) return '0.00' + symb
			return parseFloat(String(amount / 100)).toFixed(2) + symb
		}
	})
	// matches id to array of objects
	// EG cellFilter:'simple : ConstResourceGroups : description',
	// id = needle
	// arr = array of objects containing id and output field
	// field = output field (default: name)
	.filter('simple', function () {
		return function (id, arr, field = 'name') {
			if (!id || !arr) return ''
			let idx = arr.findIndex((el) => (el.id === parseInt(id, 10)))
			if (idx !== -1) return arr[idx][field]
			return 'ERROR'
		}
	})
	.filter('filterEmoticon', [function () {
		return function (type) {
			if (type === 1) return '<i class="fa fa-frown-o text-danger"></i>'
			else return '<i class="fa fa-smile-o text-success"></i>'
		}
	}])
	.filter('filterTick', [function () {
		return function (input) {
			if (!input || input === 0 || input === false || input === 'false')
				return '<i class="fa fa-times text-danger"></i>'
			else return '<i class="fa fa-check text-success"></i>'
		}
	}])
	.filter('filterBidStatus', function (SvStatic) {
		const bids = SvStatic.bidStatus()
		return function (input) {
			if (!input) return ''
			let idx = bids.findIndex((el) => (el.id === parseInt(input, 10)))
			if (idx !== -1) return bids[idx].name
			return 'Unknown'
		}
	})
	.filter('filterLotStatus', function (SvStatic) {
		const lots = SvStatic.lotStatus()
		return function (input) {
			if (!input) return ''
			let idx = lots.findIndex((el) => (el.id === parseInt(input, 10)))
			if (idx !== -1) return lots[idx].name
			return 'Unknown'
		}
	})
	.filter('filterTransactionType', function (SvStatic) {
		const types = SvStatic.transactionType()
		return function (input, field = 'name') {
			if (!input) return ''
			let idx = types.findIndex((el) => (el.id === parseInt(input, 10)))
			if (idx !== -1) return types[idx][field]
			return 'Unknown'
		}
	})
	.filter('filterTotalGST', function ($filter) {
		return function (row) {
			if (!row) return $filter('dosh')(0)
			let gst = row.surcharge_gst + row.fee_gst + row.commission_gst
			return $filter('dosh')(gst)
		}
	})
	.filter('SQLDateTime', function () {
		// converts SQL timestamp to desired format (ISO by default)
		// cellFilter:`SQLDateTime : 'ddd Mo MMM, YYYY @ hh:mma'`
		// cellFilter:`SQLDateTime | 'dddd, MMMM Do YYYY, h:mm:ss a'`
		return function (input, format = 'ISO') {
			if (!input || input === '' || input === undefined) return input
			if (format === 'ISO') input = moment(input).toISOString()
			else input = moment(input).format(format)
			return input
		}
	})
