"use strict";
const app = angular.module('App');
class TransactionService {
    constructor(toaster) {
        this.toaster = toaster;
    }
    newTransaction() {
        const transaction = {
            // Fees
            per_lot_fee: 0,
            lot_fee: null,
            lot_fee_gst: null,
            bid_sheet_fee: null,
            bid_sheet_fee_gst: null,
            surcharge: 4691,
            surcharge_gst: 469,
            commission: 0,
            commission_gst: 0,
            // Inputs
            type: 1,
            payment_method: 1,
            adjustment: 0,
            adjustment_fee: 0,
            adjustment_gst: 0,
            adjustment_gst_status: 'inclusive',
            rounding: 0,
            rounding_difference: 0,
            // Totals
            total_lot_price: 0,
            total_gst: 0,
            total: 0,
            // Outputs
            print: true,
            email: false,
            calculation: null
        };
        return transaction;
    }
    calculationPacket(customer = false, transaction = false, lots = []) {
        // Validation
        if (!customer)
            this.toaster.info('Missing customer info');
        if (!transaction)
            this.toaster.info('Missing transaction info');
        if (lots.length === 0 || !lots)
            return false; // no lots
        // Base Packet
        const Packet = {};
        Packet.transaction_type = transaction.type;
        Packet.lots = lots;
        Packet.lot_fee = transaction.per_lot_fee;
        Packet.bid_sheet_fee = transaction.bid_sheet_fee;
        // Adjustment Fees
        Packet.adjustment_fee = null;
        Packet.adjustment_fee_gst = null;
        if (transaction.adjustment_fee) {
            if (transaction.adjustment_gst_status === 'inclusive') {
                Packet.adjustment_fee = Math.round(transaction.adjustment_fee / 1.1);
                Packet.adjustment_fee_gst = Math.round(transaction.adjustment_fee / 11);
            }
            else if (transaction.adjustment_gst_status === 'exclusive') {
                Packet.adjustment_fee = transaction.adjustment_fee;
                Packet.adjustment_fee_gst = Math.round(0.1 * transaction.adjustment_fee);
            }
        }
        // Rounding: only apply if paying cash
        Packet.rounding = null;
        if (transaction.payment_method === 1)
            Packet.rounding = 5;
        // Unique Commission
        Packet.unique_commission = null;
        if (typeof customer.unique_commission === 'number' && customer.unique_commission > 0) {
            Packet.unique_commission = customer.unique_commission;
        }
        return Packet;
    } // END CalculationPacket()
}
TransactionService.$inject = ['toaster'];
app.service('TransactionService', TransactionService);
