'use strict'

angular.module('App')
	.directive('headerNotification', function () {
		return {
			templateUrl: 'html/common/header/header-notification/header-notification.html',
			restrict: 'E',
			replace: true,
			scope: {}
		}
	})
