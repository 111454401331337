"use strict";
angular.module('App')
    .controller('CtrlCustomerUpdate', function ($rootScope, $scope, $state, $stateParams, $log, $fancyModal, toaster, datePickerUtils, SvXHR) {
    let CustomerXHR = new SvXHR('customers');
    // Datepicker
    $scope.CustomerCreatedPopup = {
        format: 'dd MMMM yyyy',
        manualInput: ['dd-MMMM-yyyy', 'dd/MM/yyyy', 'dd.MM.yyyy', 'shortDate'],
        opened: false,
        options: {
            formatYear: 'yy',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(2020, 1, 1),
            startingDay: 1
        },
        open: () => {
            $scope.CustomerCreatedPopup.opened = true;
        }
    };
    $scope.CustomerDOBPopup = {
        format: 'dd MMMM yyyy',
        manualInput: ['dd-MMMM-yyyy', 'dd/MM/yyyy', 'dd.MM.yyyy', 'shortDate'],
        opened: false,
        options: {
            formatYear: 'yy',
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(2020, 1, 1),
            startingDay: 1
        },
        open: () => {
            $scope.CustomerDOBPopup.opened = true;
        }
    };
    // // FUNCTIONS
    $scope.generatePassword = () => {
        let GenerateXHR = new SvXHR('generate');
        GenerateXHR.raw('')
            .then(rsp => {
            $scope.Customer.password = rsp.data;
            $scope.formCustomer.password.$setDirty();
        })
            .catch(err => GenerateXHR.toastout(err));
    };
    $scope.CustomerShow = function (id, args = {}) {
        // enables loading state in header
        delete $scope.Customer.id;
        args = {
            'includes': 'lots,bids.lot,transactions'
        };
        $scope.LoadingModal();
        CustomerXHR.show(id, args)
            .then(rsp => {
            const tmpCust = rsp.data;
            try {
                // Parse Dates
                let dobDate = moment(tmpCust.dob);
                tmpCust.dob = dobDate.isValid() ? dobDate.toDate() : moment('1900-01-01').toDate();
                let createdDate = moment(tmpCust.created_at);
                tmpCust.created_at = createdDate.isValid()
                    ? createdDate.toDate() : moment('1980-01-01').toDate();
            }
            catch (err) {
                $log.error('Customer dates parse error', err);
            }
            $scope.Customer = tmpCust;
            $scope.formCustomer.$setPristine();
            $log.debug('Customer', $scope.Customer);
            $fancyModal.close();
        })
            .catch(err => {
            CustomerXHR.toastout(err);
            $fancyModal.close();
        });
    };
    $scope.CustomerCreateUpdateLoading = false;
    $scope.CustomerCreateUpdate = function () {
        $log.debug('UpdateForm', $scope.formCustomer);
        // Prevent Duplicate Load
        if ($scope.CustomerCreateUpdateLoading)
            return false;
        // Validation
        if ($scope.formCustomer.$invalid) {
            // bypass date parse validation
            if (_.size($scope.formCustomer.$error) > 1 || !$scope.formCustomer.$error.parse) {
                $scope.CustomerCreateUpdateLoading = false;
                return toaster.warning('Please correct invalid fields');
            }
        }
        if (!$scope.formCustomer.$dirty) {
            $scope.CustomerCreateUpdateLoading = false;
            return toaster.info('No fields changed, nothing to update');
        }
        // Prep changed fields
        let TmpCust = {};
        for (let key in $scope.formCustomer) {
            if (!$scope.formCustomer.hasOwnProperty(key))
                continue;
            if (key[0] !== '$' && $scope.formCustomer[key].$dirty || key === 'credit_rating') {
                TmpCust[key] = $scope.Customer[key];
                // Parse dates
                if (key === 'created_at' || key === 'dob')
                    TmpCust[key] = moment(TmpCust[key]).format('YYYY-MM-DD');
            }
            // store address_state for new customer
            if (key === 'address_state' && $scope.Customer.id === null)
                TmpCust[key] = $scope.Customer[key];
            // unset empty password
            if (key === 'password') {
                if (!$scope.Customer.password)
                    delete TmpCust[key];
                else if ($scope.Customer.password.length < 6)
                    return toaster.info('Password must be at least 6 characters long');
            }
        }
        // Special Input Manipulation
        if (typeof TmpCust.unique_commission !== 'number' || isNaN(TmpCust.unique_commission))
            TmpCust.unique_commission = 0;
        console.log('Fields to update: ', TmpCust);
        $scope.CustomerCreateUpdateLoading = true;
        // New Customer
        if ($scope.Customer.id === null) {
            $scope.LoadingModal();
            CustomerXHR.create(TmpCust)
                .then(rsp => {
                toaster.success('Customer Created!');
                // assign ID
                $scope.Customer.id = rsp.data.id;
                // reset form
                $scope.CustomerCreateUpdateLoading = false;
                $fancyModal.close();
                $state.transitionTo('company.customers.update', {
                    id: rsp.data.id
                }, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
            })
                .catch(err => {
                CustomerXHR.toastout(err);
                $scope.CustomerCreateUpdateLoading = false;
                $fancyModal.close();
            });
            // Existing Customer
        }
        else {
            $scope.LoadingModal();
            TmpCust.id = $scope.Customer.id;
            CustomerXHR.update(TmpCust)
                .then(rsp => {
                toaster.success('Customer Updated!');
                $scope.formCustomer.$setPristine();
                $scope.CustomerCreateUpdateLoading = false;
                $fancyModal.close();
            })
                .catch(err => {
                CustomerXHR.toastout(err);
                $scope.CustomerCreateUpdateLoading = false;
                $fancyModal.close();
            });
        }
    };
    // // STATE
    if ($stateParams.id)
        $scope.CustomerShow($stateParams.id);
    // New Customer
    else
        $scope.Customer.id = null;
}); // END CONTROLLER
