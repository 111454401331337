'use strict'

angular.module('App')
	.controller('CtrlLotUpdate', function (
		$window, $document, $rootScope, $scope, $state, $stateParams, $log, $fancyModal,
		toaster, uiGridConstants, $auth, API_URL,
		SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.GridLotsUnfiltered = [] // stores all grid lots to allow filtering closed lots
		$scope.Categories = [{
			id: 0,
			name: 'Loading..'
		}]
		$scope.hideClosedLots = false // used in grid
		$scope.SaveDisabled = false
		const LotStatusList = SvStatic.lotStatus()
		const CategoriesXHR = new SvXHR('categories')
		const CustomerXHR = new SvXHR('customers')
		const LotSheetXHR = new SvXHR('lotsheets')
		const LotXHR = new SvXHR('lots')
		// set up grid default with blank rows
		const Lot = {
			status: 1,
			created_at: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const LotDef = []
		// Datepicker
		$scope.LotSheetSignedPopup = {
			format: 'dd MMMM yyyy',
			manualInput: ['dd-MMMM-yyyy', 'dd/MM/yyyy', 'dd.MM.yyyy', 'shortDate'],
			opened: false,
			options: {
				formatYear: 'yy',
				minDate: new Date(1990, 1, 1),
				maxDate: new Date(2020, 1, 1),
				startingDay: 1
			},
			open: () => {
				$scope.LotSheetSignedPopup.opened = true
			}
		}

		// // FUNCTIONS
		$scope.CategoriesLoad = () => {
			CategoriesXHR.show(0)
				.then(rsp => {
					$scope.Categories = rsp.data
					let catIdx = $scope.GridLotSheetShow.columnDefs.findIndex(el => el.field === 'category_id')
					if (catIdx !== -1)
						$scope.GridLotSheetShow.columnDefs[catIdx].editDropdownOptionsArray = $scope.Categories
				})
				.catch(err => CategoriesXHR.toastout(err))
		}
		$scope.CategoriesLoad()
		$scope.LotSheetShow = function (id, args = {}) {
			// enables loading state in header
			delete $scope.LotSheet.visual_id
			args = {
				'id': id,
				'includes': 'lots.bids,customer'
			}
			$scope.LoadingModal()
			LotSheetXHR.index(args)
				.then(rsp => {
					if (rsp.data.length === 0) {
						toaster.error('No lots found for lotsheet ' + id)
						$scope.LotSheet = {
							signed: moment().toDate()
						}
						$scope.GridLotSheetShow.data = _.cloneDeep(LotDef)
					} else {
						// lots found
						const tmpLotSheet = rsp.data[0]
						try {
							// Parse Dates
							let signedDate = moment(tmpLotSheet.signed)
							tmpLotSheet.signed = signedDate.isValid() ? signedDate.toDate() : moment().toDate()
						} catch (err) {
							$log.error('LotSheet dates parse error', err)
						}
						$scope.LotSheet = tmpLotSheet
						$log.debug($scope.LotSheet)
						$scope.GridLotsUnfiltered = _.concat($scope.LotSheet.lots, _.cloneDeep(LotDef))
						$scope.GridLotSheetShow.data = angular.copy($scope.GridLotsUnfiltered)
					}
					$scope.Customer = $scope.LotSheet.customer
					$log.debug('LotSheet', $scope.LotSheet)
					$fancyModal.close()
				})
				.catch(err => {
					LotSheetXHR.toastout(err)
					$fancyModal.close()
				})
		} // END LotSheetShow()

		$scope.CustomerFill = function (args: any = false) {
			if (!args) args = {}
			if ($scope.Customer.id) args.id = $scope.Customer.id
			if ($scope.Customer.name_first) args.name_first = '*' + $scope.Customer.name_first + '*'
			if ($scope.Customer.name_last) args.name_last = '*' + $scope.Customer.name_last + '*'

			// empty object
			if (_.size(args) === 0) return toaster.info('Please provide an id or name to search')

			$scope.Customer.loading = true
			CustomerXHR.index(args)
				.then(rsp => {
					let dt = rsp.data
					// empty result
					if (dt.length === 0) {
						$scope.Customer.loading = false
						return toaster.info('No matching customers found')
					}
					// get first result
					$scope.Customer = _.cloneDeep(dt[0])
					$log.debug('CustomerFill', dt[0])
					$scope.Customer.loading = false
				})
				.catch(err => {
					CustomerXHR.toastout(err)
					$scope.Customer.loading = false
				})
		} // END CustomerFill()

		$scope.CustomerClear = function () {
			$scope.Customer = $scope.CustomerDefault()
		}

		$scope.LotSheetCreateUpdateLoading = false
		$scope.LotSheetCreateUpdate = function () {
			$log.debug('UpdateLotSheet', $scope.formLotSheetCustomer)

			// Prevent Duplicate Load
			if ($scope.LotSheetCreateUpdateLoading) return false

			// Validation
			if ($scope.formLotSheetCustomer.$invalid) {
				$scope.LotSheetCreateUpdateLoading = false
				return toaster.warning('Please correct invalid fields')
			}

			// PREP UPDATE PACKET
			let Packet: any = {
				customer: {},
				lot_sheet: {}
			}
			// CUSTOMER:Add any dirty form fields, add ID if existing customer
			for (let key in $scope.formLotSheetCustomer) {
				if (!$scope.formLotSheetCustomer.hasOwnProperty(key))
					continue
				if (key[0] !== '$' && $scope.formLotSheetCustomer[key].$dirty) {
					Packet.customer[key] = $scope.Customer[key]
				}
				// store address_state for new customer
				if (key === 'address_state' && $scope.Customer.id === null) {
					Packet.customer[key] = $scope.Customer[key]
				}
			}
			Packet.customer.id = $scope.Customer.id
			if (!parseInt(Packet.customer.id, 10) || Packet.customer.id === null) delete Packet.customer.id
			// BIDSHEET: Add visual_id and created_at from LotSheet obj
			Packet.lot_sheet = _.cloneDeep($scope.LotSheet)
			delete Packet.lot_sheet.customer
			// BID: Add valid, non-blank lots to lotsheet
			Packet.lot_sheet.lots = $scope.GridLotSheetShow.data.reduce((sum, lot) => {
				let tmp = _.cloneDeep(lot)
				// check reserve has been entered (requires description)
				if (tmp.description) sum.push(tmp)
				return sum
			}, [])

			Packet = SvQuery.clearNullsAuthors(Packet)

			$log.debug(Packet)
			$scope.LotSheetCreateUpdateLoading = true

			// New LotSheet
			if (!Packet.lot_sheet.id) {
				LotSheetXHR.create(Packet)
					.then(rsp => {
						toaster.success('LotSheet Created!')
						$scope.Customer = _.cloneDeep(rsp.data.customer)
						delete rsp.data.customer
						$scope.LotSheet = rsp.data
						$scope.LotSheet.signed = moment($scope.LotSheet.signed).toDate()
						$scope.GridLotsUnfiltered = _.concat($scope.LotSheet.lots, _.cloneDeep(LotDef))
						$scope.GridLotSheetShow.data = angular.copy($scope.GridLotsUnfiltered)
						// disable loading
						$scope.LotSheetCreateUpdateLoading = false
						$state.transitionTo('company.lots.update', {
							sheet_id: rsp.data.id
						}, {
							reload: true,
							inherit: false,
							notify: true
						})
					})
					.catch(err => {
						LotSheetXHR.toastout(err)
						$scope.LotSheetCreateUpdateLoading = false
					})
				// Existing LotSheet
			} else {
				LotSheetXHR.update(Packet, Packet.lot_sheet.id)
					.then(rsp => {
						toaster.success('LotSheet Updated!')
						// update scope
						$scope.LotSheet = rsp.data
						moment($scope.LotSheet.signed).toDate()
						$scope.GridLotsUnfiltered = _.concat($scope.LotSheet.lots, _.cloneDeep(LotDef))
						$scope.GridLotSheetShow.data = angular.copy($scope.GridLotsUnfiltered)
						$scope.formLotSheetCustomer.$setPristine()
						$scope.LotSheet.signed = new Date()
						$scope.LotSheetCreateUpdateLoading = false
						$window.scrollTo(0, 0)
						// $state.reload(); // DO NOT RELOAD VIEW
					})
					.catch(err => {
						LotSheetXHR.toastout(err)
						$scope.LotSheetCreateUpdateLoading = false
					})
			}
		} // END LotSheetCreateUpdate()

		$scope.LotRemove = row => {
			$log.debug('LotRemove', row)
			const lotIdx = $scope.GridLotSheetShow.data
				.findIndex((el) => (el.$$hashKey === row.entity.$$hashKey))
			if (lotIdx !== -1) {
				// undo any cell edit state
				$document[0].activeElement.blur()
				$scope.GridLotSheetShow.data.splice(lotIdx, 1)
			}
			// delete from db if row is pre-existing
			if (row.entity.id) {
				LotXHR.destroy(row.entity)
					.then(rsp => toaster.success('Lot deleted!'))
					.catch(err => LotXHR.toastout(err))
			}
		} // END LotRemove()

		$scope.TransactionShowCreate = () => {
			// Prep lot IDs array
			let lotIds = $scope.gridLotSheetShowApi.selection.getSelectedRows()
				// check lot status is open (1) or won (2)
				// .filter( lot => (lot.status == 1 || lot.status == 2) )
				// return lot id only
				.map(lot => lot.id)
			if (lotIds.length === 0) return toaster.info('No valid lots selected for transaction')

			// go to transactions create view
			$state.go('company.transactions.create', {
				'cid': $scope.Customer.id,
				'lid': lotIds.join(','),
				'type': 2 // outgoing payment (seller)
			})

			return false
		}

		$scope.CollectedToggle = row => {
			if (row.entity.collected_at) delete row.entity.collected_at
			else row.entity.collected_at = moment().toISOString()
		}

		$scope.BidderContact = row => {
			let lot = row.entity
			if (lot.status !== 2) return toaster.info('Can only find bidder for sold lot')
			let bidder = lot.bids.find(bid => bid.status === 2)
			if (bidder === -1) return toaster.error('Cannot find winning bidder')
			$state.go('company.customers.update', {
				'id': bidder.customer_id
			})
			return false
		}

		// // GRID
		$scope.GridLotSheetShow = {
			loading: false,
			enableCellEdit: true,
			showColumnFooter: true,
			enableRowSelection: true,
			multiSelect: true,
			enableSelectAll: false,
			selectionRowHeaderWidth: 35,
			filterOptions: {
				filterText: 'status:3',
				useExternalFilter: true
			},
			columnDefs: [{
					name: 'Lot #',
					field: 'visual_id',
					minWidth: 70,
					enableColumnMenu: false
				},
				{
					name: 'Description',
					field: 'description',
					cellClass: 'text-left pdg-l',
					enableColumnMenu: false,
					cellTemplate: `<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field]" uib-tooltip="{{row.entity[col.field]}}" tooltip-append-to-body="true" tooltip-placement="top"></div>` //tslint:disable-line
				},
				{
					name: 'Report?',
					field: 'police_reportable',
					width: 70,
					enableColumnMenu: false,
					cellTemplate: '<div class="ui-grid-cell-contents" ng-click="row.entity[col.field] = !row.entity[col.field]" ng-bind-html="row.entity[col.field] | filterTick"></div>' //tslint:disable-line
				},
				{
					name: 'Category',
					field: 'category_id',
					minWidth: 140,
					cellFilter: 'simple:grid.appScope.Categories',
					enableColumnMenu: false,
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: $scope.Categories
				},
				{
					name: 'Reserve',
					field: 'reserve_bid',
					cellFilter: 'dosh',
					minWidth: 90,
					enableColumnMenu: false,
					cellTemplate: '<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | dosh"></div>', //tslint:disable-line
					editableCellTemplate: '<input ng-class="' + "'" + 'colt' + "'" + ' + col.uid" ng-model="row.entity[col.field]" ui-grid-editor type="text" ng-value="row.entity[col.field] ? row.entity[col.field]/100 : 0" smart-float>',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: '<div class="ui-grid-cell-contents" ><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>' //tslint:disable-line
				},
				{
					name: 'Status',
					field: 'status',
					minWidth: 90,
					cellFilter: 'filterLotStatus',
					filter: {
						placeholder: ' Search..'
					},
					enableColumnMenu: true,
					enableHiding: false,
					menuItems: [{
						title: 'Hide Closed/Paid',
						icon: 'ui-grid-icon-info-circled',
						active: function () {
							return $scope.hideClosedLots
						},
						action: function ($event) {
							$scope.hideClosedLots = !$scope.hideClosedLots
							$scope.GridLotSheetShow.data = $scope.hideClosedLots ?
								$scope.GridLotsUnfiltered.filter((lot) => lot.status !== 4 && lot.status !== 3) :
								angular.copy($scope.GridLotsUnfiltered)
						},
						context: $scope
					}],
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: LotStatusList
				},
				{
					name: 'Sold',
					field: 'price',
					minWidth: 100,
					cellFilter: 'dosh',
					filter: {
						placeholder: ' Search..'
					},
					enableColumnMenu: false,
					enableCellEdit: false,
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: '<div class="ui-grid-cell-contents" ><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>' //tslint:disable-line
				},
				{
					name: 'Collected',
					field: 'collected_at',
					minWidth: 100,
					enableColumnMenu: true,
					enableHiding: false,
					enableCellEdit: false,
					cellTemplate: `<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'" ng-click="grid.appScope.CollectedToggle(row)"></div>`, //tslint:disable-line
					menuItems: [{
							title: 'Select All',
							icon: 'ui-grid-icon-info-circled',
							action: function ($event) {
								$scope.GridLotSheetShow.data.filter((lot) => {
									if (lot.id) lot.collected_at = moment().toISOString()
									return lot
								})
							},
							context: $scope
						},
						{
							title: 'Deselect All',
							icon: 'ui-grid-icon-cancel',
							action: function ($event) {
								$scope.GridLotSheetShow.data.filter((lot) => delete lot.collected_at)
							},
							context: $scope
						}
					]
				},
				{
					name: '',
					field: 'delete',
					enableColumnMenu: false,
					width: 40,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-click="grid.appScope.LotRemove(row)"><i class="fa fa-times grapefruit-dark"></i></div>` //tslint:disable-line
				},
				{
					name: '_',
					field: 'bidder',
					enableColumnMenu: false,
					width: 60,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-click="grid.appScope.BidderContact(row)">
<a href ng-class="{
'text-muted':row.entity.status !== 2,
'text-primary':row.entity.status === 2
}" style="text-decoration:underline;">Bidder</a>
</div>`
				}
			]
		}
		$scope.GridLotSheetShow.onRegisterApi = function (gridApi) {
			$scope.gridLotSheetShowApi = gridApi
			// EVENT LISTENERS
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				$log.debug('rowEntity:', rowEntity)
				$log.debug('colDef:', colDef, ' newVal:', newVal, ' oldVal:', oldVal)
				$scope.$apply()
				// Value Changed:
				// if(newVal != oldVal) //do a thing
			})
		}

		$scope.OpenPrintableLotSheet = function (lotsheetVisualId) {
			return window
				.open(`${API_URL}reporting/lot_sheet/${lotsheetVisualId}?token=${$auth.getToken()}`)
		}

		// // INITIALISATION
		for (let i = 0; i < 100; i++) LotDef.push(_.cloneDeep(Lot))
		$scope.GridLotSheetShow.data = _.cloneDeep(LotDef)

		// // STATE
		if ($stateParams.sheet_id) $scope.LotSheetShow($stateParams.sheet_id)
		// New LotSheet
		else $scope.LotSheet.visual_id = null
	}) // END CONTROLLER
