angular.module('App').factory('SvXHR', function (HTP, API_URL, $log, toaster, SvQuery) {
	function XHR(uri) {
		Object.defineProperty(this, 'uri', {
			set: function (newUri) {
				uri = newUri
			},
			get: function () {
				return 'yokk ' + uri
			}
		})
		this.raw = (data = '', method = 'GET') => {
			if (method === 'GET') return HTP(API_URL + uri + '?' + data)
			else return HTP(API_URL + uri, method, data)
		}
		this.index = (args = {}) => {
			return HTP(API_URL + uri + '?' + SvQuery.getEncode(args))
		}
		this.show = (id, args = {}) => {
			return HTP(API_URL + uri + '/' + id + '?' + SvQuery.getEncode(args))
		}
		this.create = (obj) => HTP(API_URL + uri, 'POST', obj)
		this.update = (obj, id = false) => {
			if (id === false) id = obj.id
			return HTP(API_URL + uri + '/' + id, 'PUT', obj)
		}
		this.destroy = (obj, id = false) => {
			if (id === false) id = obj.id
			return HTP(API_URL + uri + '/' + id, 'DELETE', obj)
		}
		this.toastout = (rsp, level = 'error') => {
			if (rsp.data) {
				if (rsp.data.message) toaster[level](rsp.data.message)
				if (rsp.data.errors) {
					console.log(typeof rsp.data.errors)
					if (typeof rsp.data.errors === 'string') toaster[level](rsp.data.message)
					else if (Array.isArray(rsp.data.errors))
						rsp.data.errors.forEach(error => toaster[level](error))
					else if (typeof rsp.data.errors === 'object') {
						for (let key in rsp.data.errors) rsp.data.errors[key].forEach(error => toaster[level](error))
					}
				}
			}
			if (!rsp.data || (!rsp.data.message && !rsp.data.errors))
				toaster[level]('Error processing request')
			if (level === 'error') $log[level](`XHR error (${API_URL + uri})`, rsp)
			return rsp
		}
	}

	return XHR
})
