'use strict'

angular.module('App')
	.controller('CtrlSellers', function ($rootScope, $scope, $state, $log, $fancyModal, toaster,
		uiGridConstants, SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.GridSellers = {}
		const LotStatusList = SvStatic.lotStatus()
			.filter(lot => ((lot.id === 2 || lot.id === 3 || lot.id === 8)))
		const LotStatusLabel = LotStatusList
			.map(lot => {
				return {
					'id': lot.id,
					'label': lot.name,
					'value': lot.id
				}
			})
			.filter(lot => lot.value !== 4)
		const LotXHR = new SvXHR('lots')
		const LotEmailXHR = new SvXHR('lots/email')

		// // FUNCTIONS
		$scope.SellersLoad = function () {
			new SvXHR('tally/lots').index({
					'latest': true
				})
				.then(rsp => {
					$scope.GridSellers.data = rsp.data
						// work out bid_value based on lot status
						.map((lot) => {
							// lot already sold
							if (lot.price) lot.bid_value = lot.price
							else {
								// UNDERBID
								if (lot.bids.length > 0) lot.bid_value = lot.bids[0].bid_value
								// find highest bid price (deprecated in issue #64)
								// let highestBidValue = 0
								// for (let bid of lot.bids)
								// if (bid.bid_value > highestBidValue) highestBidValue = bid.bid_value
								// lot.bid_value = highestBidValue
							}
							return lot
						})
					$scope.GridSellers.loading = false
				})
				.catch(err => {
					toaster.error('Error processing request')
					$log.error(err)
				})
		}

		$scope.LotUpdate = function (lot, field) {
			if (field === 'status' && lot['status'] === 2) {
				// SOLD
				let LotSoldXHR = new SvXHR('lots/' + lot.id + '/sold')
				let indexParams = (lot.bids.length > 0) ? {
					bid_id: lot.bids[0].id
				} : {}
				LotSoldXHR.index(indexParams)
					.then(rsp => toaster.success('Lot and Bids updated!'))
					.catch(err => LotSoldXHR(err))
			} else {
				let tmpLot: any = {}
				tmpLot.id = lot.id
				tmpLot[field] = lot[field]
				LotXHR.update(tmpLot)
					.then(rsp => toaster.success('Lot Status updated!'))
					.catch(err => LotXHR.toastout(err))
			}
		}

		let emailSending = false
		$scope.SellersEmail = () => {
			if (emailSending) return false
			else emailSending = true
			let lotIds = $scope.gridSellersApi.selection.getSelectedRows()
				.map(lot => lot.id)
			if (lotIds.length === 0) {
				emailSending = false
				return toaster.info('No lots have been selected')
			}
			LotEmailXHR.raw(lotIds, 'POST')
				.then(rsp => {
					toaster.success('Emails sent!')
					$state.reload()
				})
				.catch(err => {
					LotEmailXHR.toastout(err)
					emailSending = false
				})
		}

		// Cemtex
		$scope.GenerateCemtexFileName = () => moment().format('YYYY-MM-DD_hh-mm-ss') + '_cemtex.csv'
		$scope.GenerateCemtexBody = () => {
			// Selected Sellers
			let rows = $scope.gridSellersApi.selection.getSelectedRows()
			// Validation
			if (rows.length === 0) toaster.warning('Caution: no rows selected')
			// Refactor for cemtex
			// REQUIRED: bsb, acc #, full name, amount, (anything optional)
			rows = rows.map((row) => {
				delete row.customer
				return row
			})
			$log.debug('cemtex rows', rows)
			return rows
		}

		// // GRID
		$scope.GridSellers = {
			loading: false,
			enableCellEdit: true,
			enableFiltering: true,
			enableColumnMenus: false,
			multiSelect: true,
			enableSelectAll: true,
			selectionRowHeaderWidth: 35,
			columnDefs: [{
					name: 'First Name',
					field: 'customer.name_first',
					width: 150,
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Last Name',
					field: 'customer.name_last',
					width: 150,
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Lot id',
					field: 'visual_id',
					width: 90,
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Lot Description',
					field: 'description',
					minWidth: 100,
					cellClass: 'text-left pdg-l',
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field]" uib-tooltip="{{row.entity[col.field]}}" tooltip-append-to-body="true" tooltip-placement="top"></div>` //tslint:disable-line
				},
				{
					name: 'Lot Status',
					field: 'status',
					width: 100,
					cellFilter: 'filterLotStatus',
					filter: {
						term: '',
						type: uiGridConstants.filter.SELECT,
						selectOptions: LotStatusLabel,
						placeholder: ' Search..'
					},
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: LotStatusList
				},
				{
					name: 'Reserve Price',
					field: 'reserve_bid',
					width: 105,
					enableColumnMenu: false,
					enableFiltering: false,
					enableHiding: false,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | dosh"></div>`
				},
				{
					name: 'Bid Value',
					field: 'bid_value',
					width: 100,
					enableColumnMenu: false,
					enableFiltering: false,
					enableHiding: false,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | dosh"></div>`
				},
				{
					name: 'Last Contacted',
					field: 'last_contacted',
					width: 110,
					enableFiltering: false,
					enableHiding: false,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'"></div>` //tslint:disable-line
				}
			],
			data: [{
				'name': 'Loading..'
			}],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}">
</div>`
		}

		$scope.GridSellers.onRegisterApi = function (gridApi) {
			$scope.gridSellersApi = gridApi
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				// $log.debug('row:',rowEntity,' colDef:',colDef,' newVal:',newVal,' oldVal:',oldVal);
				$scope.$apply()
				if (newVal !== oldVal) {
					// prevent marking sold as underbid
					if (colDef.field === 'status' && newVal === 3 && oldVal === 2) {
						rowEntity['status'] = 2
						return toaster.warning('Lot already sold, cannot be changed to underbid')
					}
					$scope.LotUpdate(rowEntity, colDef.field)
				}
			})
		}

		// INITIALISE
		$scope.SellersLoad()
	}) // END CONTROLLER
