'use strict'

angular.module('App')
	.controller('CtrlBidders', function ($rootScope, $scope, $log, $state, $fancyModal, toaster,
		uiGridConstants, SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.GridBidders = {
			loading: true
		}
		const BidStatusList = SvStatic.bidStatus().filter(bid => (bid.id > 1 && bid.id < 5))
		const BidStatusLabel = BidStatusList.map(bid => {
			return {
				'id': bid.id,
				'label': bid.name,
				'value': bid.id
			}
		})
		const BidXHR = new SvXHR('bids')
		const BidEmailXHR = new SvXHR('bids/email')
		const BidSMSXHR = new SvXHR('bids/sms')
		const TallyBidXHR = new SvXHR('tally/bids')

		// // FUNCTIONS
		$scope.BiddersLoad = function () {
			TallyBidXHR.index({
					'latest': true
				})
				.then(rsp => {
					$scope.GridBidders.data = rsp.data
					$scope.GridBidders.loading = false
				})
				.catch(err => TallyBidXHR.toastout(err))
		}

		$scope.BidUpdate = function (bid, field) {
			let tmpBid: any = {}
			tmpBid.id = bid.id
			tmpBid[field] = bid[field]
			BidXHR.update(tmpBid)
				.then(rsp => toaster.success('Bid Status updated!'))
				.catch(err => BidXHR.toastout(err))
		}

		let emailSending = false
		$scope.BiddersEmail = () => {
			if (emailSending) return false
			else emailSending = true
			let bidIds = $scope.gridBiddersApi.selection
				.getSelectedRows()
				.map(bid => bid.id)
			// Validation
			if (bidIds.length === 0) {
				emailSending = false
				return toaster.info('No bids have been selected')
			}
			BidEmailXHR.raw(bidIds, 'POST')
				.then(rsp => {
					toaster.success('Emails sent!')
					$state.reload()
				})
				.catch(err => {
					BidEmailXHR.toastout(err)
					emailSending = false
				})
		}

		let smsSending = false
		$scope.BiddersSMS = () => {
			if (smsSending) return false
			else smsSending = true
			let bidIds = $scope.gridBiddersApi.selection.getSelectedRows()
				.map(bid => bid.id)
			if (bidIds.length === 0) {
				smsSending = false
				return toaster.info('No bids have been selected')
			}
			BidSMSXHR.raw(bidIds, 'POST')
				.then(rsp => {
					toaster.success('Sms messages sent!')
					$state.reload()
				})
				.catch(err => {
					BidSMSXHR.toastout(err)
					smsSending = false
				})
		}

		// // GRID
		$scope.GridBidders = {
			loading: false,
			enableCellEdit: true,
			enableFiltering: true,
			enableColumnMenus: false,
			multiSelect: true,
			enableSelectAll: true,
			selectionRowHeaderWidth: 35,
			columnDefs: [{
					name: 'First Name',
					field: 'customer.name_first',
					width: 150,
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Last Name',
					field: 'customer.name_last',
					width: 150,
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Lot Description',
					field: 'lot.description',
					minWidth: 100,
					cellClass: 'text-left pdg-l',
					filter: {
						placeholder: ' Search..'
					},
					enableCellEdit: false
				},
				{
					name: 'Bid Status',
					field: 'status',
					width: 120,
					cellFilter: 'filterBidStatus',
					cellClass: 'disabled',
					enableCellEdit: false,
					filter: {
						term: '',
						type: uiGridConstants.filter.SELECT,
						selectOptions: BidStatusLabel
					},
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: BidStatusList
				},
				{
					name: 'Last Contacted',
					field: 'last_contacted',
					width: 120,
					enableFiltering: false,
					enableHiding: false,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'"></div>` //tslint:disable-line
				},
				{
					name: 'Last sms',
					field: 'last_sms',
					width: 110,
					enableFiltering: false,
					enableHiding: false,
					enableCellEdit: false,
					cellTemplate: `
<div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | tsToISO | date : 'MMM dd, y'"></div>` //tslint:disable-line
				}
			],
			data: [{
				'name': 'Loading..'
			}],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}">
</div>`
		}

		$scope.GridBidders.onRegisterApi = function (gridApi) {
			$scope.gridBiddersApi = gridApi
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				$log.debug('row:', rowEntity, ' colDef:', colDef, ' newVal:', newVal, ' oldVal:', oldVal)
				$scope.$apply()
				if (newVal !== oldVal) $scope.BidUpdate(rowEntity, colDef.field)
			})
		}

		// INITIALISE
		$scope.BiddersLoad()
	}) // END CONTROLLER
