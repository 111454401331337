'use strict'

angular.module('App')
	.controller('CtrlRevenue', function (
		$rootScope, $scope, $log, $fancyModal, toaster, uiGridConstants,
		SvStatic, SvQuery, SvXHR) {
		// // VARS
		$scope.GridRevenue = {}
		$scope.Created = {
			'from': moment().subtract(1, 'months'),
			'to': moment().add(1, 'days')
		}
		$scope.PaymentMethod = {
			'selected': 0,
			'options': [{
					id: 0,
					name: 'ALL'
				},
				{
					id: 1,
					name: 'Cash'
				},
				{
					id: 2,
					name: 'EFTPOS'
				},
				{
					id: 3,
					name: 'Funds Transfer'
				}
			]
		}
    $scope.OmitReturns = true
    $scope.RevenueSummary = [];
		$scope.TransactionTypes = SvStatic.transactionType()
		const TransactionXHR = new SvXHR('transactions')

		// // FUNCTIONS
		$scope.RevenueLoad = function () {
			let queryPacket: any = {
				'includes': 'customer',
				'order_by': 'created_at,desc',
				'limit': 'unlimited',
				'created_at>': moment($scope.Created.from).toISOString(),
				'created_at<': moment($scope.Created.to).toISOString()
			}
			if ($scope.OmitReturns) queryPacket['type!'] = 4
			// Payment Method
			if ($scope.PaymentMethod.selected !== 0)
				queryPacket.payment_method = $scope.PaymentMethod.selected
			// XHR
			TransactionXHR.index(queryPacket)
				.then(rsp => {
					// Prep data for grid
					let dt = rsp.data
					for (let trns of dt) {
						let nanChecks = new Set([
							'surcharge', 'surcharge_gst', 'fee', 'fee_gst', 'commission', 'commission_gst', 'total'
						])
						for (let chk of nanChecks)
							if (isNaN(trns[chk])) trns[chk] = 0
						trns.fees_total = trns.surcharge + trns.fee
						trns.comm_total = trns.commission
						trns.gst_total = trns.surcharge_gst + trns.fee_gst + trns.commission_gst
					}
					$scope.GridRevenue.data = dt
          $scope.GridRevenue.loading = false
          $scope.CalculateSummary();
				})
				.catch(err => TransactionXHR.toastout(err))
		}

		// // GRID
		$scope.GridRevenue = {
			loading: false,
			enableColumnMenus: false,
			multiSelect: true,
			enableSelectAll: true,
			selectionRowHeaderWidth: 35,
			showColumnFooter: true,
			columnDefs: [{
					name: 'Date',
					field: 'created_at',
					width: 150,
					cellFilter: 'tsToISO | date : \'MMM dd, y\'',
					enableFiltering: false,
					enableSorting: true
				},
				{
					name: 'Receipt #',
					field: 'receipt_hash',
					width: 150,
					enableFiltering: true,
					enableSorting: true
				},
				{
					name: 'Customer Name',
					field: 'customer',
					width: 200,
					cellClass: 'text-left pdg-l',
					cellTemplate: `
<div class="ui-grid-cell-contents">{{row.entity[col.field].name_first}} {{row.entity[col.field].name_last}}</div>` //tslint:disable-line
				},
				{
					name: 'Type',
					field: 'type',
					width: 150,
					cellClass: 'text-left pdg-l',
					cellFilter: 'simple:grid.appScope.TransactionTypes'
				},
				{
					name: 'Sale Price',
					field: 'sale_price',
					width: 80,
					cellClass: 'text-left pdg-l',
					cellFilter: 'dosh',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: `<div class="ui-grid-cell-contents"><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>` //tslint:disable-line
				},
				{
					name: 'Fees',
					field: 'fees_total',
					width: 80,
					cellClass: 'text-left pdg-l',
					cellFilter: 'dosh',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: `<div class="ui-grid-cell-contents"><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>` //tslint:disable-line
				},
				{
					name: 'Commissions',
					field: 'comm_total',
					width: 110,
					cellClass: 'text-left pdg-l',
					cellFilter: 'dosh',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: `<div class="ui-grid-cell-contents"><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>` //tslint:disable-line
				},
				{
					name: 'GST',
					field: 'gst_total',
					width: 80,
					cellClass: 'text-left pdg-l',
					cellFilter: 'dosh',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: `<div class="ui-grid-cell-contents"><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>` //tslint:disable-line
				},
				{
					name: 'TOTAL',
					field: 'total',
					cellFilter: 'dosh',
					aggregationType: uiGridConstants.aggregationTypes.sum,
					footerCellTemplate: `<div class="ui-grid-cell-contents"><span ng-bind-html="col.getAggregationValue() | dosh"></span></div>` //tslint:disable-line
				}
			],
			data: [],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}">
</div>`
		}

		$scope.GridRevenue.onRegisterApi = gridApi => {
			$scope.gridRevenueApi = gridApi
		}

		// INITIALISE
    $scope.RevenueLoad()

    $scope.CalculateSummary = function () {
      const rows = $scope.GridRevenue.data as TxnResult[];
      const result: SummaryRow[] = [];
      // Incoming
      // .. Receipts
      const buyerReceipts = rows.filter(row => row.type === TxnTypeEnum.INCOMING_PAYMENT);
      const receiptsSummary: SummaryRow = {
          label: 'receipts',
          items: buyerReceipts.length,
          surcharge: buyerReceipts.reduce((sum, x) => {
            const surcharge = x.surcharge || 0;
            const fee = x.fee || 0;
            return sum + surcharge + fee;
          }, 0),
          comm: buyerReceipts.reduce((sum, x) => {
            const comm = x.commission || 0;
            return sum + comm;
          }, 0),
          tax: buyerReceipts.reduce((sum, x) => {
            const surchargeGst = x.surcharge_gst || 0;
            const feeGst = x.fee_gst || 0;
            const commGst = x.commission_gst || 0;
            return sum + surchargeGst + feeGst + commGst;
          }, 0),
          adjustment: buyerReceipts.reduce((sum, x) => {
            const adjustment = x.adjustment || 0;
            return sum + adjustment;
          }, 0),
          rounding: buyerReceipts.reduce((sum, x) => {
            const rounding = x.rounding || 0;
            return sum + rounding;
          }, 0),
          total: buyerReceipts.reduce((sum, x) => {
            const total = x.total || 0;
            return sum + total;
          }, 0),
      };
      result.push(receiptsSummary);
      // .. Refunds
      const buyerRefunds = rows.filter(row => row.type === TxnTypeEnum.REFUND);
      const refundsSummary: SummaryRow = {
          label: 'refunds',
          items: buyerRefunds.length,
          surcharge: buyerRefunds.reduce((sum, x) => {
            const surcharge = x.surcharge || 0;
            const fee = x.fee || 0;
            return sum + surcharge + fee;
          }, 0),
          comm: buyerRefunds.reduce((sum, x) => {
            const comm = x.commission || 0;
            return sum + comm;
          }, 0),
          tax: buyerRefunds.reduce((sum, x) => {
            const surchargeGst = x.surcharge_gst || 0;
            const feeGst = x.fee_gst || 0;
            const commGst = x.commission_gst || 0;
            return sum + surchargeGst + feeGst + commGst;
          }, 0),
          adjustment: buyerRefunds.reduce((sum, x) => {
            const adjustment = x.adjustment || 0;
            return sum + adjustment;
          }, 0),
          rounding: buyerRefunds.reduce((sum, x) => {
            const rounding = x.rounding || 0;
            return sum + rounding;
          }, 0),
          total: buyerRefunds.reduce((sum, x) => {
            const total = x.total || 0;
            return sum + total;
          }, 0),
        };
      result.push(refundsSummary);
      // .. Rounding
      const buyerAdjustment = result.reduce((sum, x) => (x.adjustment || 0) + sum, 0);
      const buyerRounding = result.reduce((sum, x) => (x.rounding || 0) + sum, 0);
      const roundingSummary: SummaryRow = {
        label: 'rounding',
        tax: 0,
        adjustment: buyerAdjustment,
        rounding: buyerRounding,
        total: buyerAdjustment + buyerRounding,
      };
      result.push(roundingSummary);

      // Outgoing Vendor
      const vendorPayments = rows.filter(row => row.type === TxnTypeEnum.OUTGOING_PAYMENT);
      const vendorSummary: SummaryRow = {
          label: 'vendor',
          comm: vendorPayments.reduce((sum, x) => {
            const comm = x.commission || 0;
            return sum + comm;
          }, 0),
          tax: vendorPayments.reduce((sum, x) => {
            const surchargeGst = x.surcharge_gst || 0;
            const feeGst = x.fee_gst || 0;
            const commGst = x.commission_gst || 0;
            return sum + surchargeGst + feeGst + commGst;
          }, 0),
          adjustment: vendorPayments.reduce((sum, x) => {
            const adjustment = x.adjustment || 0;
            return sum + adjustment;
          }, 0),
          rounding: vendorPayments.reduce((sum, x) => {
            const rounding = x.rounding || 0;
            return sum + rounding;
          }, 0),
          total: vendorPayments.reduce((sum, x) => {
            const total = x.total || 0;
            return sum + total;
          }, 0),
        };
      result.push(vendorSummary);

      // Totals
      const subResult = [receiptsSummary, refundsSummary, vendorSummary];
      const totalSummary: SummaryRow = {
        label: 'total',
        surcharge: receiptsSummary.surcharge - refundsSummary.surcharge,
        comm: vendorSummary.comm,
        tax: receiptsSummary.tax - refundsSummary.tax + vendorSummary.tax,
        adjustment: subResult.reduce((sum, x) => (x.adjustment || 0) + sum, 0),
        rounding: subResult.reduce((sum, x) => (x.rounding || 0) + sum, 0),
        total: receiptsSummary.total - refundsSummary.total - vendorSummary.total,
      };
      result.push(totalSummary);

      // Store to scope variable
      $scope.RevenueSummary = result;
    };
  }) // END CONTROLLER

enum PaymentMethodEnum {
  CASH = 1,
  EFTPOS = 2,
  FUNDS_TRANSFER = 3
}
type PaymentMethodType = PaymentMethodEnum.CASH | PaymentMethodEnum.EFTPOS | PaymentMethodEnum.FUNDS_TRANSFER;

enum TxnTypeEnum {
  INCOMING_PAYMENT = 1,
  OUTGOING_PAYMENT = 2,
  REFUND = 3,
  RETURN = 4
}
type TxnType = TxnTypeEnum.INCOMING_PAYMENT | TxnTypeEnum.OUTGOING_PAYMENT | TxnTypeEnum.REFUND | TxnTypeEnum.RETURN;

interface SummaryRow {
  label: 'receipts' | 'refunds' | 'rounding' | 'vendor' | 'total',
  items?: number;
  surcharge?: number;
  comm?: number;
  tax: number;
  adjustment: number;
  rounding: number;
  total: number;
}

interface TxnResult {
  id: number;
  company_id: number;
  customer_id: number;
  receipt_hash: string;
  type: TxnType;
  sale_price: number;
  surcharge?: any;
  surcharge_gst?: any;
  fee?: any;
  fee_gst?: any;
  commission: number;
  commission_gst: number;
  adjustment: number;
  adjustment_gst: number;
  rounding: number;
  payment_method: PaymentMethodType;
  total: number;
  updated_by: number;
  created_by: number;
  updated_at: string;
  created_at: string;
  deleted_at?: any;
  deleted_by?: any;
  customer: TxnCustomer;
  link: string;
  receipt: string;
}

interface TxnCustomer {
  id: number;
  company_id: number;
  name_first: string;
  name_last: string;
  preferred_name?: any;
  dob: string;
  address_number: string;
  address_name: string;
  address_type: string;
  address_suburb: string;
  address_postcode: string;
  address_state: number;
  phone_mobile: string;
  phone_primary: string;
  phone_secondary?: any;
  preferred_notification_method: number;
  email: string;
  comments?: any;
  eft_use: number;
  eft_bsb?: any;
  eft_number?: any;
  eft_name?: any;
  eft_bank?: any;
  customer_owes_balance: number;
  customer_owed_balance: number;
  credit_rating: number;
  promotional_email: number;
  unique_commission?: number;
  legacy_id?: any;
  updated_by?: number;
  created_by: number;
  updated_at: string;
  created_at: string;
  last_login_app_at?: any;
  deleted_at?: any;
  deleted_by?: any;
}
