'use strict'

// Helper factory to make XHRs syntactically cleaner
angular.module('App').factory('HTP', function ($http) {
	const httpPromise = (url, method = 'GET', data = null) => {
		return $http({
			method: method,
			url: url,
			data: data,
			timeout: 600000
		})
	}
	return httpPromise
})
