angular.module('App')
	.controller('CtrlDashboard', function (
		$rootScope, $scope, $state, $log, $fancyModal, $auth, Popeye, toaster,
		SvXHR, SvQuery) {

		// // VARS
		$scope.Panels = {
			'lotsOpen': false,
			'bidsOpen': false,
			'customers': false
		}
		$scope.QuickSearch = {
			'Customer': {
				'loading': false,
				'type': 'id',
				'input': ''
			},
			'BidSheet': {
				'loading': false,
				'type': 'visual_id',
				'input': ''
			},
			'LotSheet': {
				'loading': false,
				'type': 'lot_sheet_id',
				'input': ''
			},
			description: ''
		}
		const LotXHR = new SvXHR('lots')
		const BidXHR = new SvXHR('bids')
		const BidSheetXHR = new SvXHR('bidsheets')
		const LotSheetXHR = new SvXHR('lotsheets')
		const CustomerXHR = new SvXHR('customers')

		// // FUNCTIONS
		// Load Panel: Open Lots
		$scope.OpenLotsPanel = () => {
			$scope.Panels.lotsOpen = 'loading'
			LotXHR.index({
					'status': '1',
					'limit': 'unlimited'
				})
				.then(rsp => {
					$scope.Panels.lotsOpen = rsp.data.length
				})
				.catch(err => LotXHR.toastout(err))
		}
		// Load Panel: Lots Owing
		$scope.OpenBidsPanel = () => {
			$scope.Panels.bidsOpen = 'loading'
			BidXHR.index({
					'status': '1',
					'limit': 'unlimited'
				})
				.then(rsp => {
					$scope.Panels.bidsOpen = rsp.data.length
				})
				.catch(err => BidXHR.toastout(err))
		}
		// Load Panel: Customers
		$scope.OpenCustomersPanel = () => {
			$scope.Panels.customers = 'loading'
			CustomerXHR.index({
					'limit': 'unlimited'
				})
				.then(rsp => {
					$scope.Panels.customers = rsp.data.length
				})
				.catch(err => CustomerXHR.toastout(err))
		}
		// Quick Search
		$scope.QuickSearchSubmit = (searchObject) => {
			$scope.QuickSearch[searchObject].loading = true
			let args = {
				'limit': 1
			}
			args[$scope.QuickSearch[searchObject].type] = $scope.QuickSearch[searchObject].input
			switch (searchObject) {
				case 'Customer':
					{
						CustomerXHR
						.index(args)
						.then(rsp => {
							$scope.QuickSearch[searchObject].loading = false
							if (rsp.data.length < 1) return toaster.error('No matching customers found')
							else $state.go('company.customers.update', {
								id: rsp.data[0].id
							})
						})
						.catch(err => CustomerXHR.toastout(err))
						break
					}
				case 'BidSheet':
					{
						BidSheetXHR
						.index(args)
						.then(rsp => {
							$scope.QuickSearch[searchObject].loading = false
							if (rsp.data.length < 1) return toaster.error('No matching bid sheets found')
							else $state.go('company.bids.update', {
								sheet_id: rsp.data[0].id
							})
						})
						.catch(err => BidSheetXHR.toastout(err))
						break
					}
				case 'LotSheet':
					{
						switch ($scope.QuickSearch[searchObject].type) {
							case 'lot_sheet_id':
								{
									LotSheetXHR
									.index({
										'visual_id': $scope.QuickSearch[searchObject].input
									})
									.then(rsp => {
										$scope.QuickSearch[searchObject].loading = false
										if (rsp.data.length < 1) return toaster.error('No matching lot sheets found')
										else $state.go('company.lots.update', {
											sheet_id: rsp.data[0].id
										})
									})
									.catch(err => LotSheetXHR.toastout(err))
									break
								}
							case 'lot_id':
								{
									LotXHR
									.index({
										'visual_id': $scope.QuickSearch[searchObject].input
									})
									.then(rsp => {
										$scope.QuickSearch[searchObject].loading = false
										if (rsp.data.length < 1) return toaster.error('No matching lots found')
										else $state.go('company.lots.update', {
											sheet_id: rsp.data[0].lot_sheet_id
										})
									})
									.catch(err => LotXHR.toastout(err))
									break
								}
							case 'lot_description':
								{
									LotXHR
									.index({
										'description': '*' + $scope.QuickSearch[searchObject].input + '*'
									})
									.then(rsp => {
										$scope.QuickSearch[searchObject].loading = false
										if (rsp.data.length < 1) return toaster.error('No matching lots found')
										else $state.go('company.lots.update', {
											sheet_id: rsp.data[0].lot_sheet_id
										})
									})
									.catch(err => LotXHR.toastout(err))
									break
								}
							default:
								break
						} // END NESTED SWITCH
						break
					}
				default:
					break
			} // END SWITCH
		}

		$scope.navigateToLotSearch = function() {
			const description = $scope.QuickSearch.description
			if (!description)
				return toaster.info('Please provide a search description')
			$state.go('company.lots.search', { description })
		}

	}) // END CONTROLLER
