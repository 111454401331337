angular.module('App')
	.controller('CtrlTransaction', function (
		$window, $rootScope, $scope, $stateParams, $log, $state, $timeout, $fancyModal, toaster,
		SvXHR, Popeye, TransactionService) {
		// // VARS
		$scope.User = $rootScope.User
		$scope.Def = {
			created_at: new Date(),
			loading: false
		}
		$scope.Company = {
			loading: true
		}
		$scope.Customer = $scope.CustomerDefault()
		const CustomerXHR = new SvXHR('customers')
		const CompanyXHR = new SvXHR('companies')
		const TransactionXHR = new SvXHR('transactions')
		// Index
		$scope.GridTransactionIndex = _.cloneDeep($scope.Def)
		// Create
		$scope.Transaction = TransactionService.newTransaction()
		$scope.GridTransactionLots = _.cloneDeep($scope.Def)

		// // FUNCTIONS
		$scope.CompanyLoad = () => {
			CompanyXHR.show(0)
				.then(rsp => {
					$scope.Company = rsp.data
					$scope.Transaction.per_lot_fee = (typeof $scope.Company.lot_fee === 'number')
						? $scope.Company.lot_fee : 0
					$scope.Transaction.bid_sheet_fee = (typeof $scope.Company.bid_sheet_fee === 'number')
						? $scope.Company.bid_sheet_fee : 0
				})
				.catch(err => CompanyXHR.toastout(err))
		}
		$scope.CompanyLoad()

		$scope.TransactionIndex = function (args: any = {}) {
			$scope.GridTransactionIndex.loading = true
			// set customer
			args.customer_id = $scope.Transaction.customer_id
			CustomerXHR.show($scope.Transaction.customer_id).then(
				function (rsp) {
					$scope.Customer = rsp.data
					console.log('customer', $scope.Customer)
					TransactionXHR.index(args).then(
						function (rsp2) {
							$log.debug('Transaction Index:', rsp2)
							$scope.GridTransactionIndex.data = _.cloneDeep(rsp2.data)
							$scope.GridTransactionIndex.loading = false
						}).catch(
						function (err) {
							toaster.error('Error processing request')
							$log.error(err)
						})
				}).catch(
				function (err) {
					toaster.error('Error loading customer')
					$log.error(err)
				})
		}

		$scope.TransactionPrint = (row) => {
			$window.open(row.entity.link)
		}

		$scope.TransactionEmail = (row) => {
			console.log('row email', row)
			// Prep Modal
			Popeye.openModal({
				template: `
<div class="row">

<div class="col-xs-12"><h3 class="page-header" style="margin-top:0;">Email Customer</h3></div>

<div class="col-xs-12">
<form class="form-group form-grass">
<div class="input-group">
<span class="input-group-addon">Email Address</span>
<input type="text" name="email" class="form-control" placeholder="" ng-model="EmailAddress">
</div>
</form>
</div>
<div class="col-xs-6 col-xs-offset-3 mgn-t pdg-t">
<button class="form-control btn btn-success" type="button" ng-click="EmailSend()">
Send Email</button>
</div>

</div>
`,
				scope: $scope,
				controller: [function () {
					// Vars
					$scope.EmailAddress = $scope.Customer.email
					$scope.EmailSend = () => {
						const TransactionEmailXHR = new SvXHR('transactions/email/' + row.entity.id)
						TransactionEmailXHR.raw('email=' + $scope.EmailAddress)
							.then(rsp => {
								toaster.success('Email sent!')
								return Popeye.closeCurrentModal()
							})
							.catch(err => TransactionEmailXHR.toastout(err))
					} // END EmailSend()
				}] // END Controller
			})
		}

		// // GRID
		$scope.GridTransactionIndex = {
			loading: false,
			enableFiltering: true,
			useExternalSorting: true,
			enableColumnMenus: false,
			enableCellEdit: false,
			columnDefs: [{
					name: 'Date',
					field: 'created_at',
					cellFilter: 'tsToISO | date : \'MMMM dd, y @ h:mma\'',
					enableFiltering: false,
					enableSorting: true
				},
				{
					name: 'Receipt #',
					field: 'receipt',
					filter: {
						placeholder: ' Search..'
					},
					enableSorting: false
				},
				{
					name: 'Type',
					field: 'type',
					cellFilter: 'filterTransactionType:\'short\'',
					filter: {
						placeholder: ' Search..',
						enableFiltering: false
					}
				},
				{
					name: 'Sale Price',
					field: 'sale_price',
					cellFilter: 'dosh',
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: 'Total Price',
					field: 'total',
					cellFilter: 'dosh',
					filter: {
						placeholder: ' Search..'
					}
				},
				{
					name: '',
					field: 'delete',
					enableColumnMenu: false,
					width: 60,
					enableCellEdit: false,
					enableFiltering: false,
					cellTemplate: `
<div class="ui-grid-cell-contents pull-left" ng-click="grid.appScope.TransactionPrint(row)"><i class="fa fa-print text-warning"></i></div><div class="ui-grid-cell-contents pull-left" ng-click="grid.appScope.TransactionEmail(row)"><i class="fa fa-envelope text-primary"></i></div>` // tslint:disable
				}
			],
			rowTemplate: `
<div ui-grid-cell
 class="ui-grid-cell"
 ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
 ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}">
</div>`
		}
		$scope.GridTransactionIndex.onRegisterApi = function (gridApi) {
			$scope.gridTransactionIndexApi = gridApi
			// EVENT LISTENERS
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				$log.debug('row:', rowEntity, ' colDef:', colDef, ' newVal:', newVal, ' oldVal:', oldVal)
				$scope.$apply()
				// Value Changed: if(newValue != oldValue)
			})
			$scope.gridTransactionIndexApi.core.on.filterChanged($scope, function () {
				$scope.SorterFilter(this.grid)
			})
			$scope.gridTransactionIndexApi.core.on.sortChanged($scope, function () {
				$scope.SorterFilter(this.grid)
			})

			// FILTERING + PRIORITIZED SORTING
			$scope.SorterFilter = function (grid, newPage = false, pageSize = false) {
				let queryParams = grid.columns.reduce((sum, col, idx) => {
					if (!sum.sorts) sum.sorts = []
					if (!sum.order_by) sum.order_by = []
					// FILTERS
					if (angular.isDefined(col.filters[0].term) && col.filters[0].term) {
						// do not wildcard ID search
						if (col.field === 'id') sum[col.field] = col.filters[0].term
						else sum[col.field] = '%' + col.filters[0].term + '%'
					}
					// SORTERS
					if (angular.isDefined(col.sort.direction) && col.sort.direction) {
						sum.sorts.push({
							'field': col.field,
							'direction': col.sort.direction,
							'priority': col.sort.priority
						})
						sum.sorts = sum.sorts.sort((a, b) => {
							return a.priority - b.priority
						})
						sum.order_by = sum.sorts.reduce((sum, sort) => {
							let tmp = (sum === '') ? '' : '|'
							return sum + tmp + sort.field + ',' + sort.direction
						}, '')
					}
					if (idx === grid.columns.length - 1) delete sum.sorts
					return sum
				}, {})
				// Include Relations
				queryParams.includes = 'customer'
				$scope.queryTimeout(queryParams)
			} // END SorterFilter()

			// Query XHR + Debounce
			$scope.queryTimeout = function (queryParams) {
				if (angular.isDefined(this.timer)) $timeout.cancel(this.timer)
				this.timer = $timeout(() => {
					$scope.TransactionIndex(queryParams)
				}, 500)
			}
		} // END onRegisterApi()

		// // CLEANUP
		$scope.$on('$destroy', function (event) {
			if (angular.isDefined($scope.queryTimeout)) {
				$timeout.cancel($scope.queryTimeout)
			}
		})
	}) // END CONTROLLER
