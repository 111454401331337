angular.module('App')
	.controller('CtrlTally', function (
		$rootScope, $scope, $stateParams, $log, $filter, $fancyModal, $auth, Popeye, toaster,
		SvXHR, SvStatic, uiGridConstants, SvQuery) {

		const TallyProcessXHR = new SvXHR('tally/process')
		const BidStatusList = SvStatic.bidStatus().filter(bid => bid.id > 1 && bid.id < 5)
		const BidStatusLabel = BidStatusList.map(bid => {
			return { id: bid.id, label: bid.name, value: bid.id }
		})
		const BidOptions = [
			{ id: 1, name: 'Cancel' },
			{ id: 2, name: 'Re-bid' },
			{ id: 3, name: 'Keep Sold' }
		]

		// Process Tally
		$scope.TallyExecuteModal = function () {
			Popeye.openModal({
				templateUrl: 'html/common/modals/tally-confirm.html',
				scope: $scope,
				controller: [function () {
					$scope.Close = () => Popeye.closeCurrentModal({
						'name': 'tally-confirm',
						message: 'closed'
					})
					$scope.TallyLoading = false
					$scope.TallyLink = false
					$scope.TallyExecute = () => {
						if ($scope.TallyLoading) return false
						else $scope.TallyLoading = true
						TallyProcessXHR.index()
							.then(rsp => {
								toaster.success('Tally processed!')
								$scope.TallyLink = rsp.data
								$scope.TallyLoading = false
							})
							.catch(err => {
								Raven.captureException(err)
								TallyProcessXHR.toastout(err)
								$scope.TallyLoading = false
							})
						// Refresh token for new tally value
						$auth.login()
							.then(rsp => {
								$rootScope.User = $scope.User = $auth.getPayload().user
								$log.debug('User token refreshed', $rootScope.User)
							})
							.catch(err => {
								$log.debug('err', err)
								$rootScope.User = $scope.User = false
								toaster.info('Please re-login to update your portal')
							})
					} // END TallyExecute();
				}] // END Controller
			})
		}

		// // FUNCTIONS
		$scope.UncollectedBids = function () {
			$scope.GridBids.loading = true
			const xhr = new SvXHR('bids/uncollected')
			xhr.index()
				.then(rsp => {
					$scope.GridBids.data = rsp.data
					$scope.GridBids.loading = false
				})
				.catch(err => xhr.toastout(err))
		}

		// // GRID
		$scope.GridBids = {
			loading: false,
			enableFiltering: true,
			enableColumnMenus: false,
			enableCellEdit: false,

			columnDefs: [
				{
					name: 'First Name',
					field: 'customer.name_first',
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'Last Name',
					field: 'customer.name_last',
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'CID',
					cellClass: 'text-left pdg-l',
					field: 'customer.id',
					width: 80,
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'Description',
					cellClass: 'text-left pdg-l',
					field: 'lot.description',
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'Lot id',
					cellClass: 'text-left pdg-l',
					field: 'lot.visual_id',
					width: 80,
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'Reserve',
					cellClass: 'text-left pdg-l',
					field: 'lot.reserve_bid',
					cellFilter: 'dosh',
					width: 100,
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'Bid',
					cellClass: 'text-left pdg-l',
					field: 'bid_value',
					cellFilter: 'dosh',
					width: 100,
					enableFiltering: false,
					enableSorting: false
				},
				{
					name: 'Seller',
					cellClass: 'text-left pdg-l',
					field: 'lot.customer',
					enableFiltering: false,
					enableSorting: false,
					cellTemplate: `
						<div class="ui-grid-cell-contents">
							{{ row.entity.lot.customer.name_first }} {{ row.entity.lot.customer.name_last }}
						</div>
					`
        },
        {
          name: 'Kept Sold?',
          cellClass: 'text-center pdg-l',
          field: 'is_keep_sold',
          enableFiltering: false,
          enableSorting: true,
          width: 90,
          cellTemplate: `
            <div class="ui-grid-cell-contents" ng-bind-html="row.entity[col.field] | filterTick"></div>
          `
        },
				{
					name: 'Options',
					cellClass: 'text-center pdg-l',
					field: 'options',
					width: 150,
					enableFiltering: false,
					enableSorting: false,
					enableCellEdit: true,
					editableCellTemplate: 'ui-grid/dropdownEditor',
					editDropdownValueLabel: 'name',
					editDropdownOptionsArray: BidOptions,
					cellTemplate: `
						<div class="ui-grid-cell-contents">
							<div style="border:1px solid #bbb">
								Select
							</div>
						</div>
					`
				}
			],
			rowTemplate: `
				<div ui-grid-cell
				class="ui-grid-cell"
				ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"
				ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}"
				ng-click="grid.appScope.BidSelected(row)">
				</div>
			`
		}
		$scope.GridBids.onRegisterApi = function (gridApi) {
			$scope.gridBidsApi = gridApi
			gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newVal, oldVal) {
				// $log.debug('row:',rowEntity,' colDef:',colDef,' newVal:',newVal,' oldVal:',oldVal);
				$scope.$apply()
				if (colDef.field === 'options') {
					const bidId = rowEntity.id
					$scope.UpdateUncollected(bidId, newVal)
				}
			})
		}

		$scope.UpdateUncollected = function (bidId: number, bidAction?: 1 | 2 | 3) {
      if (!bidAction) {
        return toaster.info('No action selected');
      }
			$scope.GridBids.loading = true
			const xhr = new SvXHR('bids/uncollected')
			xhr.create({ id: bidId, action: bidAction })
				.then(rsp => {
					console.log('response data', rsp.data)
					toaster.success('Bid Updated!')
					$scope.UncollectedBids()
				})
				.catch(err => {
					xhr.toastout(err)
				})
		}

		// STATE
		$scope.UncollectedBids()

	}) // END CONTROLLER
